.stepOneWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
}

.middleContent{
    margin-top: 20px;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0px; */
  margin-top: 12px;
}

.bottomContainerWrapper {
  width: 100%;
}

.dimensionListWrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-height: 320px;
  width: 100%;
  overflow-x: auto;
}

.dimensionBtn {
  margin-top: 12px;
  width: fit-content;
  padding: 8px 12px;
  background: #F7F2FE;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 4px;
}

.dimensionBtn_active {
  background: #9747FF;
}

.dimensionTextWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
}

.dimensionTextWrapper_active{
  color: #fff !important
}
