/* .HeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  /* background: yellow; */
/* padding: 12px 0px; */
/* }  */

/* .navigationLinkWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
} */

.coinIconWrapper {
  display: flex;
  background-color: #ef8b6e;
  min-width: 141px;
  min-height: 40px;
  background: #ffffff;
  border-radius: 100px;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-right: 8px;
  padding: 0px 10px;
}

.goldCoinIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  background: #ffffff;
}
.goldCoinIconWrapper img {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

.profileInfoWrapper {
  /* width: 213px; */
  /* background: pink; */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.premiumButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 18px 20px;
  gap: 8px;
  width: 117px;
  height: 40px;
  background: #ef8b6e;
  border-radius: 16px;
  margin-right: 8px;
}

.notificationButton {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.show_menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 16px;
  gap: 8px;
  width: 144px;
  height: 40px;
  background: #ffffff;
  border-radius: 100px;
  cursor: pointer;
}
.show_menu_text {
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
}
.showMenuOpen {
  padding: 12px 0px !important;
  margin-left: 60px;
}
.sideBar {
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 0px 0px 20px;
  gap: 20px;
  isolation: isolate;
  position: absolute;
  width: 64px;
  height: 100vh;
  left: 0px;
  top: 0px;
  background: #ffffff;
  z-index: 9;
}
.sideBarOpen {
  display: flex !important;
}

/* POPOEVR */
.popoverContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 16px;

  width: 400px;

  /* Main/White */

  background: #ffffff;
  box-shadow: 0px 0px 34px rgba(25, 13, 26, 0.1);
  border-radius: 12px;
}
.popovertitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  width: 100%;
  justify-content: space-between;
}
.popovertitle1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  /* identical to box height, or 18px */

  letter-spacing: -0.01em;

  color: #190d1a;
}
.popoverbutton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  text-align: right;
  letter-spacing: -0.01em;

  /* Main/Purple */

  color: #9747ff;
}
.popoverBody {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;

  width: 100%;
}

.popoverTabs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;
  isolation: isolate;

  width: 107px;
  height: 32px;

  /* Main/Background */

  background: #f9f9f9;
  border-radius: 10px;
}

.popovertab {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;

  /* width: 65px;
  height: 28px; */

  /* Main/Background */

  background: #f9f9f9;
  border-radius: 8px;
}

.popoverselectedtab {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 12px;
  /* 
  width: 38px;
  height: 28px; */

  /* Main/White */

  background: #ffffff;
  box-shadow: 0px 0px 72px rgba(25, 13, 26, 0.12);
  border-radius: 8px;
}

.popovertabText {
  /* width: 41px;
  height: 12px; */

  /* Desktop/Button/Primary XS • 400 • 12 */

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}

.popoverselectedtabText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  letter-spacing: -0.01em;

  /* Main/Purple */

  color: #9747ff;
}
.notificationLists {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
}

.notificationItem {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  cursor: pointer;
}
.notificationIcon {
  position: relative;
  box-sizing: border-box;

  /* Auto layout */

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  isolation: isolate;

  width: 40px;
  height: 40px;

  /* Main/Purple-Tertiary */

  background: #f7f2fe;
  /* Main/White */

  border: 1px solid #ffffff;
  border-radius: 100px;
}
.badgeIcon {
  position: absolute;

  right: 0;
  top: 0;

  /* Main/Purple */

  background: #9747ff;
}

.notificationIconText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* or 14px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  /* Purple / Primary */

  color: #9747ff;

  height: 40px;
  width: 40px;
  border-radius: 100%;
}

.notificationContent {
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 12px;

}
.notificationContentData {
  display: flex;
flex-direction: column;
align-items: flex-start;
padding: 0px;
gap: 2px;
}

.notificationContentDataTitle {
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 135%;
/* or 16px */

display: flex;
align-items: center;
letter-spacing: -0.01em;

/* Main/Black */

color: #190D1A;

}

.notificationContentDataDate {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 120%;
/* identical to box height, or 12px */

display: flex;
align-items: center;
letter-spacing: -0.01em;

/* Main/Dark Gray */

color: #434243;
}

.notificationContentButton {
  display: flex;
flex-direction: row;
align-items: center;
padding: 8px 12px;
gap: 4px;


/* Main/Purple-Tertiary */

background: #F7F2FE;
border-radius: 16px;
}

.notificationContentButtonLabel {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 100%;
/* identical to box height, or 12px */

display: flex;
align-items: center;
letter-spacing: -0.01em;

/* Main/Purple */

color: #9747FF;
}