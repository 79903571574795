.stepOneWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 40px;
}
.all_goals{
  margin-top: 8px;
  
}

.nextButton {
  /* padding: 18px 20px; */
  width: 100%;
  max-width: 560px;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
  font-size: 1rem;
}
.nextButton:hover {
  /* background: pink; */
  border: 1px solid #a25bfe;
  color: #ffff;
  background: #a25bfe;
}
.nextButton:disabled {
  background: #f9f9f9;
  border: none !important;
}
.customRadio {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  justify-content: space-between;
  padding: 16px;
}
.customRadio:hover {
  border: 1px solid #e5d5fc;
}
.checkBoxText {
  padding: 0px 8px;
  display: flex;
  align-items: center;
}
.checkedCustomRadio {
  width: 100%;
  display: flex;
  border-radius: 16px;
  justify-content: space-between;
  padding: 16px;
  border: 1px solid #9747ff;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}
.progressTittle {
  width: 123px;
  text-align: center;
  color: #000000;
}
.dataNotFoundSubWrapper {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}
