.main_wrapper {
    background-image: linear-gradient(
      180deg,
      rgba(229, 213, 252, 0.3),
      rgba(255, 209, 114, 0.3)
    );
  }
  .sideBarToogle {
    position: fixed ;
    z-index: 9 ;
    background: #ffffff ;
    width: 13.55rem ;
    height: 100% ;
    /* transition: all 2s ease-in-out */
  }
