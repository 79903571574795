.closebutton{
    position: absolute;
    right: 20px;
    cursor: pointer;
    width: 12px;
    height: 12px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 20px;
}

.closebutton img {
    width: 12px;
    height: 12px;
}