.dashbordTitleContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  padding: 24px;
  border-radius: 20px;
  gap: 12px;
}
.dataNotFoundSubWrapper {
  display: flex;
  justify-content: center;
}
.dashbordTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashbordTitlebody {
  display: flex;
  flex-direction: column;
}
.mainWrapper {
  /* height: calc(100vh - 230px); */
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
  height: auto;
}
.smallMainWrapper {
  height: calc(100vh - 300px);
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 20px;
}
.AccordianSummery {
  margin: 0px 0px !important;
}
.schedualScoreWrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}
.goalScoreWrapper {
  width: 100%;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  justify-content: center;
}

/* subAccordian */
.subAccordianSummery {
  margin: 0px 0px !important;
  padding: 0px 1px !important;
  min-height: 20px !important;
}
.plusImage {
  display: flex;
  padding: 10px;
}