.Container404 {
  display: flex;
  height: calc(100vh - 65px);
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 32px;
}
.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;
  user-select: none;
}
.illubox {
  width: 210px;
  height: 180px;
  position: relative;
}
.rectangle {
  position: absolute;
  width: 210px;
  height: 180px;
  left: calc(50% - 210px / 2);
  top: 0px;

  /* Gradient/Purple */

  background: linear-gradient(135.69deg, #f1edff 3.39%, #d5cafb 107.13%);
  border: 12px solid rgba(255, 255, 255, 0.5);
  border-radius: 30px;
}
.textContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;
}

.premiumButton {
  padding: 19px 20px;
  gap: 6px;

  height: 56px;

  /* Main/Purple */
  color: #fff;
  font-weight: 400;
  font-size: 18px;
  background: #9747ff;
  border-radius: 100px;
}
.premiumButton:hover {
    background: #9747ff;
  border-radius: 100px;
}