.action_data {
  border-radius: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  gap: 6px;
  border: 1px solid #f3f3f3;
}
.checkBox {
  width: 13px;
  height: 12px;
  border: 1px solid #434243;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.dropIcon {
  color: #434243;
  width: 12px;
  height: 12px;
}
.textAction {
  width: 92px;
  height: 40px;
  overflow: hidden;
  padding-right: 10px;
  display: flex;
  align-items: center;
  line-height: 12px;
  font-size: 13px;
}
