.appLogo {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 105%;
  color: #190d1a;
  gap: 6px;
  display: flex;
  align-items: center;
}

.betaTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  gap: 10px;
  border-radius: 4px;
  height: 18px;
}

.sideBarItems {
  margin-top: 2.4375rem;
  /* background: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sideBarIcons {
  margin-left: 2px;
}

.sidebarBottomIcon {
  margin-left: 2px;
  height: 24px;
  width: 24px;
}

.sideBarLogoutButton {
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 100%;
}

.ActiveMenuItems {
  color: "#9747FF";
}

.appGrowthLogo {
  /* Logo_Box */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 64px;
  height: 64px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  z-index: 0;
}

.logoBox {
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  gap: 10px;

  width: 36px;
  height: 36px;

  /* Main/Purple */
  background: #9747ff;
  border-radius: 100px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.logoText {
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;

  /* identical to box height, or 24px */
  letter-spacing: -0.01em;

  /* Main/White */
  color: #ffffff;
}
