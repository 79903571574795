.lastchaild:last-child {
  border-right: none;
}
.box_drop {
  border-top: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  /* min-height: 74px; */
  text-align: center;
  max-width: 40px;
}
.blank_header {
  border-bottom: none;
  width: 73px;
}
.days_header {
  border-bottom: none;
  text-align: center;
}
.box {
  border: 1px solid;
  height: 85%;
  margin: auto;
  text-align: center;
  overflow-wrap: anywhere;
}
.first_tablerow{
  height: 120px;
}
.first_Of_End {
  margin-left: 10px;
  border-radius: 10px 0px 0px 10px;
  border-top: 2px dashed #e5d5fc;
  border-bottom: 2px dashed #e5d5fc;
  border-left: 2px dashed #e5d5fc;
}
.center_Of_End {
  border-top: 2px dashed #e5d5fc;
  border-bottom: 2px dashed #e5d5fc;
}
.last_Of_End {
  margin-right: 10px;
  border-radius: 0px 10px 10px 0px;
  border-top: 2px dashed #e5d5fc;
  border-bottom: 2px dashed #e5d5fc;
  border-right: 2px dashed #e5d5fc;
}
.box_ga_Center {
  position: relative;
  border-top:1px solid #f3f3f3 !important;
  border-bottom: 1px solid #f3f3f3 !important;
  height: 49px;
  /* margin-top: 8px; */
  margin: 6px 0px 0px 0px;
  display: flex;
  align-items: center;
}
.box_ga_Center {
  position: relative;
  border-top:1px solid #f3f3f3 !important;
  border-bottom: 1px solid #f3f3f3 !important;
  height: 49px;
  /* margin-top: 8px; */
  margin: 6px 0px 0px 0px;
  display: flex;
  align-items: center;
}
.question_box {
  position: absolute;
  top: 20%;
  left: 10%;
  width: 100vw;
  z-index: 1;
  text-align: left;
}
.ele_text {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 300%;
}
.dimention_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 220px;
  height: 12px;
}
.dimention_name {
  height: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: black;
}
.box_color{
  margin-top:8px ;
  position: relative;
  height: 45px;
  width: 101%;
}
.box_redius{
  margin-right: 10px;
  border-radius: 0px 10px 10px 0px;
  height: 45px;
  margin-top: 8px;
}
.box_start_radius{
  margin-left: 10px;
  border-radius: 10px 0px 0px 10px;
}
.clip{
  overflow-y: clip;
}
.start_Date {
  height: 49px;
  /* margin-top: 8px;
  margin-left: 10px; */
  margin: 6px 0px 0px 8px;
  display: flex;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  border-left: 1px solid #f3f3f3;
}
.end_Date {
  width: 94%;
  margin: 6px 8px 0px 0px;
  display: flex;
  align-items: center;
  border-top: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  border-radius: 0px 10px 10px 0px;
  border-right: 1px solid #f3f3f3;
  height: 49px;
  opacity: 1;
}
.createMoreGoalWrapper {
  width: 44px;
  height: 44px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  bottom: 60px;
  right: 20px;
  /* box-shadow: 8px 0px 84px rgba(25, 13, 26, 0.15); */
}
.action_data {
  border-radius: 4px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 6px;
  gap: 6px;
}
.textAction {
  width: 92px;
  height: 24px;
  overflow: hidden;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.action_box {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* padding: 15px; */
  justify-content: center;
  align-items: center;
}
.unspecified {
  /* display: flex;
  flex-direction: column;
  align-items: flex-start; */
  position: absolute;
  width: 98.5%;
  height: 150px;
  top: 80.5%;
  background: #ffffff;
  box-shadow: 0px -4px 74px rgb(25 13 26 / 10%);
  border-radius: 16px;
  z-index: 2;
  width: 98.333;
  /* gap: 15px; */
  overflow-y: scroll;
}
.unspecified_text {
  width: 87px;
  height: 14px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #434243;
  position: absolute;
  top: 15px;
  left: 77px;
  /* margin: 15px; */
}
.grid_Box{
  border: 1px solid #f3f3f3;
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 35px 10px;
  min-height: 150px;
}
.second_tablerow{
  height: 150px;
}
.box_drop_Unspecified {
  border-top: 1px solid #f3f3f3;
  border-right: 1px solid #f3f3f3;
  border-bottom: 1px solid #f3f3f3;
  min-height: 74px;
  text-align: center;
  width: 140px;
}