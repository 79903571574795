.dashbordTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    padding: 24px;
    border-radius: 20px;
    gap: 12px;
    /* height: 90vh; */
    overflow-y: auto;
  }