.file_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #190d1a;
}

.file_type {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #434243;
}
.file_body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 120px;
  gap: 12px;
  width: 100%;
  height: 180px;
  background: #ffffff;
  border: 1px dashed #dad6da;
  border-radius: 12px;
  margin-top: 16px;
  /* background-color: #F7F2FE; */
}

.inbody {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #190d1a;
  cursor: pointer;
}

.inbody span {
  padding-left: 5px;
  color: #9747ff;
}
.inbody_max {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #434243;
}
.bug_padding {
  margin-top: 32px;
}

.input_file {
  display: none;
}

.file_Uploaded {
  margin-top: 24px;
  margin-bottom: 16px;
}

.upload_file {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  gap: 8px;
  width: 100%;
  height: 54px;
  background: #ffffff !important;
  border: 1px solid #f3f3f3 !important;
  border-radius: 12px;
  margin-bottom: 12px;
}

.files {
  height: 40px !important;
  width: 20px !important;
}

.file_div {
  display: flex;
  /* flex-direction: row; */
  justify-content: space-between;
}

.delete_file {
  text-align: end;
  width: 100%;
}

.file_name{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: -0.01em;
color: #190D1A;
}

.file_size{
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 10px;
line-height: 120%;
display: flex;
align-items: center;
letter-spacing: -0.01em;
color: #434243;
margin-top: 4px;
}
