.main_box {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 145%;
    letter-spacing: -0.01em;
    color: #190d1a;
  }
  .divider_box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 0px;
    gap: 10px;
    width: 216px;
    height: 25px;
  }
  .hr_line {
    border: 1px solid #f7f2fe;
    width: 100%;
  }
  .DateAndTimewrapper {
    height: 14px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  