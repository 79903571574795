.stepOneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
  position: relative;
}
.middleContent {
  margin-top: 20px;
}
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  /* border: 1px solid #fff; */
}
.wrapper :hover {
  /* border-radius: 16px;
  border: 1px solid #e5d5fc; */
}
.bottomContainerWrapper {
  width: 100%;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
