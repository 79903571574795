.dashbordTitleContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    padding: 24px;
    border-radius: 20px;
    gap: 12px;
  }
  
  .dashbordTitle {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .dashbordTitlebody {
    display: flex;
    flex-direction: column;
  }
  .mainWrapper {
    height: calc(100vh - 230px);
    /* height: calc(100vh - 370px); manasvi */
    overflow: auto;
    margin-top: 16px;
    background-color: #ffffff;
    padding: 24px;
    border-radius: 20px;
    /* margin-bottom: 16px; */
  }
  .testListWrapper {
    margin-bottom: 20px;
  }
  
  .progressBarWrapper {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .progress {
    height: 12px;
    border-radius: 100px;
    background: #f7f2fe;
  }
  
  .progress span {
    border-radius: 100px;
  }
  
  .cardHeader {
    margin-top: 12px;
  }
  
  .card {
    height: 296px;
    background: #ffffff;
    border-radius: 20px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .imageWrapper img {
    width: 100%;
    max-height: 96px;
    border-radius: 12px;
  }
  /* css for personality result */
  .PersonalityResultcontainer {
    /* padding-top: 3px; */
  }
  .personalityReslutItem {
    display: flex;
    margin: 6px 0px;
    padding: 6px 6px;
  }
  .personalityResultText {
    color: #434243;
    font-size: 10px;
    line-height: 14px;
    width: 45%;
  }
  .personalityResultpercentage {
    font-size: 10px;
    line-height: 14px;
    width: 10%;
    text-align: right;
  }
  .TestQuestionSlider {
    height: 10px;
    width: 45%;
  }
  
  /* social card css */
  
  .socialGrid{
  background: #FFFFFF;
  border: 1px solid #F3F3F3;
  border-radius: 16px;
  padding: 16px;
  }
  .socialCardWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: "pointer";
    /* justify-content: space-between; */
  }
  .headerWrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    flex-wrap: wrap;
  }
  