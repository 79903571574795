.topContainerWrapper {
  width: 100%;
}

.middleContainerWrapper {
  /* width: 100%; */
  /* width: 457px; */
  /* min-width: 250px; */
  /* background: yellow; */
}

.bottomContainerWrapper {
  width: 100%;
}

.questionCount {
  color: #9747ff;
}

.thumbIconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.answerWrapper {
  width: 100%;
}

.sliderCustomLabel {
  background: yellow;
}

/* buttons */

.bottomContainerWrapper {
  /* background: yellow; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonWrapper {
  max-width: 457px;
}

.previousButton {
  padding: 18px 20px;
  width: 100%;
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #f9f9f9;
  border-radius: 16px;
}

.previousButton:disabled {
  border: none !important;
}

.previousButton:hover {
  /* border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff; */

  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

.nextButton {
  padding: 18px 20px;
  width: 100%;
  /* border: 1px solid #9747FF; */
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
}
.nextButton:disabled {
  background-color: #f9f9f9;
  color: rgba(0, 0, 0, 0.26);
  border: none !important;
}
.nextButton:hover {
  /* background: pink; */
  /* border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff; */

  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}

.iconOfProgress {
  /* position: absolute; */
  
  /* Main/White */

  border: 2px solid #ffffff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  font-size: 7px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 5;
}
