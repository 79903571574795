.previous_btn {
  padding: 18px 20px;
  width: 182px;
  border: 1px solid #9747ff;
  background-color: #9747ff;
  color: #ffff;
  border-radius: 16px;
  margin: 16px auto auto auto;
  display: flex;
}
