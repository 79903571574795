.push_mail{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 100%;
display: flex;
align-items: center;
letter-spacing: -0.01em;
color: #434243;
padding: 10px 0px;
}

.notification_title{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 145%;
display: flex;
align-items: center;
letter-spacing: -0.01em;
color: #190D1A;
/* padding-bottom: 20px; */
}

.layout{
display: flex;
width: 100%;
justify-content: space-between;
padding: 0px;
gap: 16px;
background: #FFFFFF;
margin: 16px 0px;
}

.layout_lable{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 150%;
display: flex;
align-items: center;
letter-spacing: -0.01em;
color: #434243;
}

.line{
background-color: #e9e8e9;
height: 1px;
border: none;

}
.backbutton{
    margin:0px 15px -3px 0px;
}
