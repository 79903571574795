.bug_padding {
  margin-top: 32px;
}
.item_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-bottom: 1px solid #f3f3f3;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* overflow-x:scroll */
}
.set_grid {
  margin-top: 32px;
}
.text_area:hover {
  border: 1px solid #e5d5fc;
}
.set_input {
  margin-top: 32px;
}
.file_heading {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #190d1a;
}
.file_type {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  margin-top: 4px;
  align-items: center;
  letter-spacing: -0.01em;
  color: #434243;
}
.file_body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  width: 100%;
  height: 180px;
  background: #ffffff;
  border: 1px dashed #dad6da;
  border-radius: 12px;
  margin-top: 16px;
  /* background-color: #F7F2FE; */
}
.inbody {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #190d1a;
  cursor: pointer;
  margin: 0px 40px;
}
.inbody span {
  padding-left: 5px;
  color: #9747ff;
}
.inbody_max {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;
  color: #434243;
}
.input_file {
  display: none;
}
.file_Uploaded {
  margin-top: 24px;
  margin-bottom: 16px;
}
.upload_file {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 8px;
  width: 100%;
  height: 54px;
  background: #ffffff !important;
  border: 1px solid #f3f3f3 !important;
  border-radius: 12px;
  margin-bottom: 12px;
}
.files {
  height: 40px !important;
  width: 20px !important;
}
.file_div {
  display: flex;
  justify-content: space-between;
}
.delete_file {
  text-align: end;
  width: 100%;
}
.file_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #190d1a;
}
.file_size {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 120%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #434243;
  margin-top: 4px;
}
.email_error {
  margin: 10px;
  color: #ee3434;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
}

/* *** mobile responsive *** */
.mob_set_grid {
  margin-top: 16px;
}
