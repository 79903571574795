.maincontainerWrapper {
  width: 100%;
  height: 272px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 20px;
  padding: 24px;
  display: flex;
}
.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.headerWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.headerTitleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ScoreHighLowWrapper {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}
.testScores {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 5px;
}

.testResultScore {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  margin-bottom: 16px;
}
.subResultScore {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}
.reflectiveQuestions {
  padding: 24px;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.rqHeaderWrapper {
  display: flex;
  gap: 8px;
  align-items: center;
}
.userIconeWrapper {
  background: #f7f2fe;
  border: 1px solid #ffffff;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  padding-top: 4px;
}
.rQmainWrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0;
  gap: 20px;
}
.breakLine {
  background: #f7f2fe;
  width: 100%;
  height: 1px;
}

/* .categoryListTab:disabled{
  background-color: green !important;
} */

/* img card css ****************************************** */

.cardHeader {
  margin-top: 12px;
}

.card {
  min-height: 352px;
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #f3f3f3;
  width: 100%;
}
.subcard {
  min-height: 352px;
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #f3f3f3;
  width: 100%;
}
.imageResultWrapper {
  position: relative;
}
.imageResultWrapper img {
  width: 100%;
  max-height: 120px;
  border-radius: 12px;
}
.imageWrapper img {
  width: 100%;
  max-height: 120px;
  border-radius: 12px;
}
.Imageoverlay {
  position: relative;
}
.Imageoverlay img {
  object-fit: cover;
}
.Imageoverlay::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 98%;
  width: 100%; /* set to 100% for full overlay width */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 12px;
}
.text_wrapper{
  margin: 0px;
  font-size: 14px;
  font-weight: 500;
  color: #190D1A;
}
.iconsWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  max-height: 24px;

  /* padding: 12px 14px; */
  /* background-color: #9747FF; */
}
.iconsLeftWrapperDisable {
      /* width: 152px; */
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 12px;
      left: 12px;
}
.commingSoonicon {
  padding: 4px 8px;
    background: #f7f2fe;
    border-radius: 100px;
    /* width: 45px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.testTitleDisableSoonCard {
  margin-top: 14px;
  width: 80%;
  height: 26px;
  position: relative;
  overflow: hidden;
}
.testTitleDisableSoonCard1 {
  margin-top: 14px;
  width: 100%;
  height: 22px;
  position: relative;
  overflow: hidden;
}
.testTitleDisableSoonCard2 {
  margin-top: 7px;
  width: 80%;
  height: 22px;
  position: relative;
  overflow: hidden;
}
.testTitleDisableSoonCard3 {
  margin-top: 7px;
  width: 55%;
  height: 22px;
  position: relative;
  overflow: hidden;
}


.ImageoverlaySoonCard {
  background: #ffffff;
  border-radius: 16px;
  position: relative;
  height: 160px;
  z-index: 2;
  overflow: hidden;
}

.questionImage1 {
  position: absolute;
 
  left: calc(50% - 40px / 2 + 82px);
  top: calc(50% - 160px / 2 + 36px);

  /* Main/White */

  color: #ffffff;
  transform: rotate(30deg);
  z-index: 2;
  font-size: 100px;
}
.questionImage2 {
  position: absolute;
 
  left: calc(50% - 40px / 2 - 82px);
  top: calc(50% - 160px / 2 + 36px);

  /* Main/White */

  color: #ffffff;
  transform: rotate(-30deg);
  z-index: 2;
  font-size: 100px;
}
.questionImage3 {
  position: absolute;
  
  left: calc(50% - 60px / 2);
  top: calc(50% - 160px / 2);

  /* Main/White */

  color: #ffffff;
  z-index: 2;
  font-size: 140px;
}

.sooncardBackground {
  position: absolute;
  width: 470px;
  height: 406px;
  left: -43px;
  top: -96px;
  z-index: 1;
}

.ellipse1 {
  position: absolute;
  width: 207px;
  height: 208px;
  left: 263px;
  top: 0px;

  background: #fce9a5;
  filter: blur(57px);
  z-index: 1;
}

.ellipse2 {
  position: absolute;
  width: 243px;
  height: 176px;
  left: 0px;
  top: 0px;

  background: #d8e1fa;
  filter: blur(32px);
  z-index: 1;
}

.ellipse3 {
  position: absolute;
  width: 204px;
  height: 205px;
  left: 222px;
  top: 133px;

  background: #dcfaf0;
  filter: blur(37px);
  z-index: 1;
}
.ellipse4 {
  position: absolute;
  width: 273px;
  height: 273px;
  left: 0px;
  top: 133px;

  background: #fff8bd;
  filter: blur(52px);
  z-index: 1;
}
