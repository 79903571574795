.growthRight {
    width: 22px;
    height: 22px;
    background: #EEE8E3;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    color: #190D1A;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}

.growthLeft {
    color: #190D1A;
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
}

.growthAccordianHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.growthAccordion {
    /* width: 304px; */
    height: auto;
    /* Main/White */
    background: #FFFFFF;
    border-radius: 16px !important;
    margin: 16px 0;
}
.growthAccordion::before {
    height: 0;
}

.growthEngagement {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: 24px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 1;
}

.growthEngagementSpan {
    /* width: calc(100% - 40px); */
    /* height: 22px; */
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    letter-spacing: -0.01em;
    /* Main/Black */
    color: #190D1A;
}

.growthAccordianSegmentControl {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 2px;
    isolation: isolate;
    justify-content: center;
    /* width: 280px;
    height: 32px; */

    /* Main/Background */
    background: #F9F9F9;
    border-radius: 10px;

    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.boxTypography {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    height: 28px;
    /* Main/White */
    box-shadow: 0px 0px 72px rgba(25, 13, 26, 0.12);
    border-radius: 8px;
}

.boxTypographyP {
    width: auto;
    height: 12px;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.01em;
    /* Main/Black */
    color: #190D1A;
}

.boxActive {
    background: #FFFFFF;
}

.boxTypographyActiveP {
    color: #9747FF !important;
}

.growthAccordionListContainer {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    margin: 10px 0px;
    gap: 12px;
    /* width: 280px; */
    height: auto;
    /* Main/Background */
    background: #F9F9F9;
    border-radius: 12px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.growthAccordionDataContent {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    height: auto;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.dataContentTypography {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.01em;
    color: #716F6B;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.dataContentTypographyRecommend {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.01em;
    color: #9747FF;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    display: flex;
    flex-grow: 0;
    align-items: center;
    gap: 4px;
}

.dataContentBoxItem {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px;
    gap: 8px;
    width: 100%;
    /* height: 84px; */
    /* Main/White */
    background: #FFFFFF;
    border-radius: 12px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.dataContentActionsBoxItem {
    /* height: 112px !important; */
}

.dataContentBoxTypography {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 145%;
    /* or 20px */
    letter-spacing: -0.01em;
    /* Main/Black */
    color: #190D1A;
    /* Inside auto layout */
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.dataContentTag {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    gap: 4px;
    width: 48px;
    height: 20px;
    /* Main/Purple-Tertiary */
    background: #F7F2FE;
    border-radius: 100px;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
}

.dataContentSpan {
    font-weight: 400;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */
    letter-spacing: -0.01em;
    /* Main/Purple */
    color: #9747FF;
}

.segmentButtonGrid {
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    gap: 4px;
    width: 95px;
    height: 28px;
    /* Main/White */
    background: #FFFFFF;
    border-radius: 16px;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

.segmentButtonText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    height: 20px;
    flex: none;
    order: 0;
    flex-grow: 0;
}