.dashbordTitleContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    padding: 24px;
    border-radius: 20px;
    gap: 12px;
}
.dashbordTitle {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.dashbordTitlebody {
    display: flex;
    flex-direction: column;
}

.storeContainer {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    padding: 24px;
    border-radius: 20px;
    gap: 12px;
    min-height: calc(100% - 200px);
    overflow-y: scroll;
    margin-top: 12px;    
    margin-bottom: 20px;
}

.set_btn {
    background: #9747FF;
    border-radius: 100px;
    font-weight: 400;
    font-size: 14px;
    padding: 12px 16px;
    font-size: 0.875rem;
}

.classForAmount {
    margin-left: 3px;
}