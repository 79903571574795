.mainWrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow-y: auto;
}
.InnerWrapper {
  padding: 0px 24px;
}
.heading{
    font-weight: 600;
    line-height: 36.8px;
}
.SearchWrapper {
  margin: 4px 0px;
}
.nameWrapper {
  display: flex;
  border: 1px solid #f3f3f3;
  padding: 15px 16px;
  border-radius: 16px;
}
.ShortNamewrapper {
  background: #f7f2fe;
  width: 24px;
  height: 24px;
  font-size: 10px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  color: #9747ff;
}
.NameClass {
  padding: 0px 8px;
  font-family: "Inter";
  font-weight: 500;
  color: #190d1a;
}

@media screen and (max-width: 600px) {
  .mainWrapper {
    padding: 10px;
  }
  .InnerWrapper {
    padding: 0px;
  }
  .heading {
    font-size: 25px;
  }
}
