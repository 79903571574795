.StepThreeWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 40px;
}
.StepThreeSubWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 16px 0px;
  width: 100%;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}
.progressTittle {
  width: 123px;
  text-align: center;
  color: #000000;
}
