.mainWrapper {
  width: 100%;
  padding: 12px;
  margin: auto;
}
.editInnerWrapper {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}
.DateAndTimewrapper {
  height: 40px;
  display: flex;
  align-items: center;
  gap: 8px;
}
.dateAndTimeText {
  color: #9747ff;
  cursor: pointer;
}
.calanderWrapper {
  padding: 24px;
}
.dateTimeRangePickerWrapper{
    width: 100%;
    display: flex;
    gap: 8px;
}
.dateTimeRangePickerSubWrapper {
    width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  height: 48px;
  align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
}
