.main_wrapper {
  background-image: linear-gradient(
    180deg,
    rgba(229, 213, 252, 0.3),
    rgba(255, 209, 114, 0.3)
  );
}

.carousel-root {
  height: 100%;
}