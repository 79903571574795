.invitePageWrapper {
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  
  .headingWrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
  }
  .buttonWrapper {
    width: 100%;
  }
  .headingText {
    text-align: center;
  }
  
  .subHeadingWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .subHeadingText {
    width: 85%;
    text-align: center;
  }
  
  .coinIconWrapper {
    width: fit-content;
    height: 20px;
    background: #f7f2fe;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    margin-top: 8px;
  }
  
  .coinIconWrapper img {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  
  .buttonGroup{
    margin-bottom: 12px;
  }