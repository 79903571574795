.calendarInfo {
    margin-top: 1rem;
    padding: 24px;
    /* min-height: 381px; */
    background: #ffffff;
    border-radius: 20px;
  }

  .calendarTitle {
    display: flex;
    flex-direction: column;
  }
  
  .divider {
    margin: 16px 0;
    border-color: #F7F2FE;
  }

  .scheduleInfoWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .scheduleButton {
    /* width: 70px; */
    padding: 8px 12px;
    background: #e5d5fc;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .scheduleDataWrapper {
    background: #ffffff;
    border: 1px solid #e5d5fc;
    border-radius: 12px;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .scheduleData {
    display: flex;
    align-items: center;
    gap: 8px;
  }