.select_field {
  width: 100%;
}
.allInput {
  width: 100%;
  background-color: #f9f9f9;
  outline: none;
  border-radius: 16px;
  border: none;
  top: 6%;
  opacity: 99;
  font-weight: 400;
  font-size: 16px;
  padding-left: 16px;
  margin-left: 0px;
}
.allInput :hover {
  border: 1px solid #e5d5fc;
}

.inpt {
  outline: none;
  border: transparent;
  /* background-color: blue !important; */
  /* width: 100%; */
}
.MuiOutlinedInput-root {
  outline: none;
}
.textFieldName {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #190d1a;
  margin: 8px 0px 12px 0px;
}
.list_item {
  text-align: left;
  padding: 20px;
  gap: 10px;
  height: 56px;
  background: #ffffff;
  border-bottom: 1px solid #f3f3f3;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.nt_select{
    text-align: left;
  gap: 10px;
  height: 56px;
  /* background: #ffffff; */
  border-bottom: 1px solid #f3f3f3;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border-radius: 16px;
}

.option_item{
  max-height: 100px;
 max-width: 100px !important;
}
