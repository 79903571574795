.stepOneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.middleContent {
  margin-top: 20px;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0px; */
  margin-top: 12px;
  border: 1px solid #fff;
}
.wrapper:hover {
  border: 1px solid #e5d5fc;
  border-radius: 16px;
}

.bottomContainerWrapper {
  width: 100%;
}
