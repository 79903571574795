.dashbordTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #ffff;
    padding: 24px;
    border-radius: 20px;
    gap: 12px;
    height: 90vh;
    overflow-y: auto;
  }



.main_wrapper {
  display: flex;
  align-items: flex-start;
  padding: 16px;
  gap: 20px;
  width: 100%;
  height: 162px;
  background: #f7f2fe;
  border-radius: 16px;
  flex: auto;
  order: 0;
  flex-grow: 0;
  margin-top: 25px;
  margin-left: 1px;
}

.days_plan {
  width: 100%;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #190d1a;
}
.days {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
}
.details {
  width: 100%;
  display: flex;
  gap: 10px;
}
.details_premium {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  gap: 24px;
  width: 50%;
  height: 488px;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 24px;
  /* padding-right: 8rem; */
}

.details_heading {
  width: 364px;
  height: 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  color: #9747ff;
}
.in_Style {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #190d1a;
}
.list {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 200%;
      color: #434243;
  text-align: start;
 
}
.list_heading {
  color: #9747ff;
  font-weight: 600;
}

.plan_price{
padding: 24px 0px;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 100%;
color: #434243;
}
.price{
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 120%;
color: #190D1A;
}
/* .list{
    border: 1px solid black;
    width: 100rem ;
} */
