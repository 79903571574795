.box_name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;
  width: 220px;
  height: 50px;
  background: #ffffff;
}
.dimention_box {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 4px;
  width: 220px;
  height: 12px;
}
.dimention_name {
  height: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: black;
}
.new_question {
  width: 220px;
  height: 34px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: black;
  overflow: auto;
}
.divider_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 0px;
  gap: 10px;
  width: 216px;
  height: 25px;
}
.hr_line {
  border: 1px solid #f7f2fe;
  width: 100%;
}
.duration_time {
  width: 198px;
  height: 12px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.01em;
  color: #190d1a;
}
.time {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: 220px;
  height: 16px;
}
.color_Picker {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 6px;
  width: 220px;
  height: 16px;
  cursor: pointer;
}
.color_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 8px;
  gap: 4px;
  width: 216px;
  height: 100px;
}
.color_collection {
  width: 51px;
  height: 16px;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color_Column {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 5px;
  width: 250px;
  height: 16px;
}
.lock{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3px;
}