.calendar_Box {
    height: 120px;
    border-top: 2px dashed #e5d5fc;
    border-bottom: 2px dashed #e5d5fc;
  }
  .calendar_date_box {
    display: flex;
    flex-wrap: wrap;
  }
  .box_area {
    height: 100%;
    width: 100%;
    border-top: 1px dashed #9747ff;
    border-bottom: 1px dashed #9747ff;
  }
  .week_box {
    border-bottom: none;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    /* padding: 0px 16px; */
    font-size: 12px;
    font-weight: 400;
    line-height: 110%;
    color: #434243;
    height: 54px;
  }
  .first_cell {
    padding: 0px;
    position: relative;
  }
  td:first-child {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  .first_cell:first-child .first_div:first-of-type {
    border-radius: 10px 0px 0px 10px;
    border-left: 2px dashed #e5d5fc;
  }
  
  .first_Of_End {
    margin-left: 10px;
    border-radius: 10px 0px 0px 10px;
    border-top: 2px dashed #e5d5fc;
    border-bottom: 2px dashed #e5d5fc;
    border-left: 2px dashed #e5d5fc;
  }
  .center_Of_End {
    border-top: 2px dashed #e5d5fc;
    border-bottom: 2px dashed #e5d5fc;
  }
  .last_Of_End {
    margin-right: 10px;
    border-radius: 0px 10px 10px 0px;
    border-top: 2px dashed #e5d5fc;
    border-bottom: 2px dashed #e5d5fc;
    border-right: 2px dashed #e5d5fc;
  }
  .box_ga_Center {
    position: relative;
    border-top: 1px solid #f3f3f3 !important;
    border-bottom: 1px solid #f3f3f3 !important;
    height: 49px;
    margin: 6px 0px 0px 0px;
    display: flex;
    align-items: center;
    width: 101%;
  }
  .start_Date {
    height: 49px;
    margin: 6px 0px 0px 8px;
    display: flex;
    align-items: center;
    border-radius: 10px 0px 0px 10px;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-left: 1px solid #f3f3f3;
  }
  .end_Date {
    width: 94%;
    margin: 6px 8px 0px 0px;
    display: flex;
    align-items: center;
    border-top: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    border-radius: 0px 10px 10px 0px;
    border-right: 1px solid #f3f3f3;
    height: 49px;
    opacity: 1;
  }
  .box {
    border-top: none !important;
    border-bottom: none !important;
  }
  .question_box {
    position: absolute;
    top: 20%;
    left: 10%;
    width: 100vw;
    z-index: 1;
  }
  .ele_text {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    width: 300%;
  }
  .dimention_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 4px;
    width: 220px;
    height: 12px;
  }
  .dimention_name {
    height: 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: black;
  }
  .box_color {
    margin-top: 8px;
    position: relative;
    height: 45px;
    width: 101%;
  }
  .box_redius {
    margin-right: 10px;
    border-radius: 0px 10px 10px 0px;
    height: 49px;
    margin-top: 6px;
  }
  .box_redius2 {
    /* margin-right: 10px; */
    /* border-radius: 0px 10px 10px 0px; */
    /* height: 49px; */
    /* margin-top: 6px; */
    /* background-color: #f3f3f3; */
  }
  .box_start_radius {
    margin-left: 10px;
    border-radius: 10px 0px 0px 10px;
  }
  .box_start_radius2 {
    /* margin-left: 10px; */
    /* border-radius: 10px 0px 0px 10px; */
  }
  .clip {
    overflow-y: clip;
  }
  .occupy {
    /* width: 100%;
    height: 50px; */
    margin-top: 10px;
    height: 45px;
    background-color: white;
  }
  .action_data {
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    gap: 6px;
  }
  .action_box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* padding: 15px; */
    justify-content: center;
    align-items: center;
  }
  .textAction {
    width: 92px;
    height: 24px;
    overflow: hidden;
    padding-right: 10px;
    display: flex;
    align-items: center;
  }
  