.stepTwoWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 40px;
}
.ImageWrapper {
  width: 58px;
  height: 58px;
  border: 1px solid #f3f3f3;
  border-radius: 50%;
  margin: 16px auto;
}
.stepTwoSubWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 16px 0px;
  width: 100%;
}
.radioWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 6px 0px;
}
.customRadio {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  justify-content: center;
  padding: 16px;
  margin-bottom: 12px;
}
.checkBoxText {
  padding: 0px 8px;
  display: flex;
  align-items: center;
  text-align: center;
}
.checkedCustomRadio {
  width: 100%;
  display: flex;
  border-radius: 16px;
  justify-content: center;
  padding: 16px;
  border: 1px solid #9747ff;
  margin-bottom: 12px;
  position: relative;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}
.progressTittle {
  width: 123px;
  text-align: center;
  color: #000000;
}
.customRadio:hover {
  border: 1px solid #e5d5fc;
}
