.emptybox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 186px;
  gap: 16px;

  position: absolute;
  width: 100%;
  /* Main/White */

  background: #ffffff;
}
.emptyContentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;

  width: 100%;
  /* height: 276px; */
}

.emptyActionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 16px;
  isolation: isolate;

  width: 100%;
}

.emptyTextBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
}
.graphicalBox {
    width: 319px;
height: 202px;
}