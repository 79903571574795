.subAccordianSummery {
  margin: 0px 0px !important;
  padding: 0px 1px !important;
  min-height: 20px !important;
}
.GrowthAccordianDetailContainer {
  padding: 0px !important;
}
.customRadioActionGroup {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 11.5px;
  margin: 12px 0px;
  position: relative;
  flex-direction: column;
}
.customRadioActionAction {
  width: 100%;
  display: flex;
  padding: 0 11.5px;
  margin: 12px 0px;
  position: relative;
}
.customRadioAction {
  width: 100%;
  display: flex;
  padding: 0 11.5px;
  margin: 12px 0px;
  position: relative;
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 11.5px;
  margin: 12px 0px;
  position: relative;
}
.GrowthAccordianDetail {
  width: 100%;
  border: 1px solid #f3f3f3;
  padding: 16px;
  border-radius: 12px;
  margin: 12px 0px;
}
.customRadio {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  justify-content: space-between;
  padding: 16px;
  margin: 12px 0px;
}
.checkBoxText {
  padding: 0px 8px;
  color: black;
  font-size: 14px;
}
.schedualWrapper {
  display: flex;
  gap: 15px;
}

.actionNameWrapper {
  display: flex;
  width: 100%;
  align-items: center;
}
.dataNotFoundSubWrapper {
  display: flex;
  justify-content: center;
}
.PlusIconWrapper {
  display: flex;
  align-items: center;
}
.selectedWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.checkBoxIcon {
  width: 12px;
  height: 12px;
  margin: 0px 4px;
  cursor: pointer;
}
.checkBoxChekedText {
  padding: 0px 8px;
  color: #434243;
  font-size: 14px;
  text-decoration: line-through;
}
.duplicateTextWrapper {
  justify-content: center;
  width: 56px;
  height: 28px;
  border-radius: 16px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  gap: 4px;
}
.backgroundChip {
  background: #f7f2fe;
  border-radius: 100px;
  color: #9747ff;
  height: 20px;
}
.backgroundChip .MuiChip-avatar {
  margin-right: -16px;
}
.backgroundChip img {
  height: 12px;
  width: 12px;
}
.backgroundChip span {
  font-size: 0.75rem;
}

.recommendedClass {
  color: #9747FF;
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: space-between;
}