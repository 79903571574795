.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.addMoreQuestionBtn {
  margin-bottom: 12px;
  margin-top: 12px;
  width: fit-content;
  padding: 8px 12px;
  background: #f7f2fe;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addmoreTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
