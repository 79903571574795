.stepOneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.middleContent {
  margin-top: 41px;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0px; */
  margin-top: 12px;
  border-radius: 16px;
  border: 1px solid #f9f9f9;
}
.wrapper:hover {
  border: 1px solid #e5d5fc;
}

.nextButton {
  padding: 18px 20px;
  width: 100%;
  /* border: 1px solid #9747FF; */
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
}

.nextButton:hover {
  /* background: pink; */
  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}

.previousButton {
  padding: 18px 20px;
  width: 100%;
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #f9f9f9;
  border-radius: 16px;
}

.previousButton:hover {
  /* border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff; */

  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

/* link section */

.linkWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.linkContent {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.add_email {
  height: 56px;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.add_description {
  min-height: 180px;
  background: #f9f9f9;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
