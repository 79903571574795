.validationError input {
  background: #f5ebe8 !important;
  border-color: #ef8b6e !important;
  color: #ef8b6e !important;
}

/* .validationMessage {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;

  margin-top: 8px;
} */

.passwordIconButton {
  width: 40px;
  height: 40px;
  /* background: beige; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.passwordIconButton svg {
  width: 20px;
  height: 30px;
}
.inputhover:hover {
  border: 1px solid green;
}
