.carousel-root {
    height: 100%;
  }

  .carousel-slider {
    height: 100%;
  }

  .carousel .slider-wrapper {
    height: 100%;

  }

  .carousel .slider.animated {
    height: 100%;

  }

  .carousel .slide img {
    height: 100%;
  }

  .carousel .control-dots {
    bottom: -10px !important
  }

  /* rgb(151, 71, 255) */

  .carousel .control-dots .dot {
    background: rgb(151, 71, 255) !important
  }