
.mainWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: relative;
}
.testEndPageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 546px;
}
.coinIconWrapper{
    width: 47px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f7f2fe;
    border-radius: 100px;

    margin-top: 8px;
}

.coinIconWrapper img{
  width: 12px;
  height: 12px;
}

.earnedMsg{
    margin-top: 20px;
}

/* button */

.bottomContainerWrapper{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: 85%;
    position: absolute;
    bottom: 0;
}

.previousButton {
    padding: 18px 20px;
    width: 100%;
    border: 1px solid #9747ff;
    color: #9747ff;
    background: #f9f9f9;
    border-radius: 16px;
  }
  
  
  .previousButton:hover {
    /* border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff; */

    border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff;
  }
  
  .nextButton {
    padding: 18px 20px;
    width: 100%;
    /* border: 1px solid #9747FF; */
    border-color: #9747ff;
    background: #9747ff;
    border-radius: 16px;
    color: #ffffff;
  }
  
  .nextButton:hover {
    /* background: pink; */
    /* border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff; */

    border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff;
  }
  
  