.buttonWrapper {
    /* max-width: 457px; */
  }
  
  .previousButton {
    padding: 18px 20px;
    width: 100%;
    border: 1px solid #9747ff;
    color: #9747ff;
    background: #f9f9f9;
    border-radius: 16px;
  }
  
  .previousButton:hover {
    /* border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff; */

    border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff;
  }

  .previousButton:disabled{
    border: none !important;
  }

  .nextButton:disabled{
    background: #f9f9f9;
    border: none !important;
  }
  
  .nextButton {
    padding: 18px 20px;
    width: 100%;
    /* border: 1px solid #9747FF; */
    border-color: #9747ff;
    background: #9747ff;
    border-radius: 16px;
    color: #ffffff;
  }
  
  .nextButton:hover {
    /* background: pink; */
    /* border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff; */

    border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff;
  }
  