.RightSideWrapper {
  /* border: 1px solid blueviolet; */
  position: relative;
  width: 100%;
}
.closeIconeWrapper{
  position: absolute;
  right: 0;
  background: #FFFFFF;
  margin-right:18px ;
  display: flex;
  align-items: center;
  justify-content: center;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.inputWrapper {
  margin: auto;
  text-align: center;
}
.sliderAndstepperWrapper {
  margin: 0px 28px;
  text-align: center;
}
.headingtext {
  margin-top: 24px;
}

.leftSideContent {
  display: "flex";
  height: "100%";
}
.addMoreQuestionBtn {
  margin-bottom: 12px;
  margin-top: 12px;
  width: fit-content;
  padding: 8px 12px;
  background: #f7f2fe;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addmoreTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 600px) {
  .headingtext {
    font-size: 24px;
  }
}
