.StepThreeWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  padding: 0 40px;
}
.StepThreeSubWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 16px auto;
  width: 100%;
  max-height: calc(100vh - 340px);
  overflow: auto;
}
.radioWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.growthAreaSelection {
  border: 1px solid purple;
}

/* previous next */

.previousButton {
  padding: 18px 20px;
  width: 100%;
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #f9f9f9;
  border-radius: 16px;
}

.previousButton:hover {
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

.previousButton:disabled {
  border: none !important;
}

.nextButton:disabled {
  background: #f9f9f9;
  border: none !important;
}

.nextButton {
  padding: 18px 20px;
  width: 100%;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
}

.nextButton:hover {
  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.progressBarWrapper {
  width: 100%;
  display: flex;
  margin-top: 24px;
  justify-content: center;
  gap: 8px;
}
.progressTittle {
  width: 124.5px;
  text-align: center;
  color: #000000;
}
