.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 24px;
  width: calc(100% - 63px);
  height: 34px;
  justify-content: space-between;
  padding: 24px 0px;
}
.header_month_week {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 18px;
}
.month_year_text {
  font-weight: 600;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.right_icon,.left_icon, .icon{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.icon{
    gap: 6px;
}