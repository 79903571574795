.item_list {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-bottom: 1px solid #f3f3f3;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  /* overflow-x:scroll */
}
.bug_padding {
  margin-top: 32px;
}
.set_grid {
  margin-top: 32px;
}
.text_area:hover {
  border: 1px solid #e5d5fc;
}

/* *** mobile responsive *** */

.mob_set_grid{
  margin-top: 16px;
}

.mob_bug_padding {
  margin-top: 32px !important;
}
.email_error{
margin: 10px ;
  color:#ee3434;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 14px;
}



