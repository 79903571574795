.set_grid {
  /* width: 100%; */
  margin-top: 32px;
}
.text_area:hover {
  border: 1px solid #e5d5fc;
}
.text_area {
  text-align: start;
  width: 100% !important;
  padding: 16px;
  border-radius: 16px;
  color: #434243 !important;
  background-color: #f9f9f9;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid transparent;
  outline: none;
}

.email_error {
  margin: 10px;
  color: #ee3434;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
}

/* *** mobile responsiv *** */
.mob_set_grid {
  margin-top: 16px;
}