.mainWrapper {
  height: calc(100vh - 230px);
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
}
.smallMainWrapper {
  height: calc(100vh - 300px);
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 12px;
  border-radius: 20px;
}
.notAddGoalWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.createGoalsBtn {
  padding: 18px 20px;
  border: 1px solid #9747ff;
  color: #fff;
  background-color: #9747ff;
  border-radius: 16px;
}
.createGoalsBtn:hover {
  background-color: #a25bfe;
}
