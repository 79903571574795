.previousButton {

    width: 100%;
    border: 1px solid #9747ff;
    color: #9747ff;
    background: #f9f9f9;
    border-radius: 16px;
    padding: 4px 8px;
  }
  
  .previousButton:hover {
    border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff;
  }
  
  .previousButton:disabled {
    border: none !important;
  }
  
  .nextButton:disabled {
    background: #f9f9f9;
    border: none !important;
  }
  
  .nextButton {

    width: 100%;
    border-color: #9747ff;
    background: #9747ff;
    border-radius: 16px;
    color: #ffffff;
    padding: 4px 8px;
  }
  
  .nextButton:hover {
    border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff;
  }
  
  .progressBarWrapper {
    width: 100%;
    display: flex;
    margin-top: 24px;
    justify-content: center;
    gap: 8px;
  } 