.subAccordianSummery {
    margin: 0px 0px !important;
    padding: 0px 1px !important;
    min-height: 20px !important;
  }
  .GrowthAccordianDetailContainer {
    padding: 0px !important;
  }
  .customRadioAction {
    width: 100%;
    display: flex;
    border: 1px solid #f3f3f3;
    border-radius: 16px;
    padding: 11.5px;
    margin: 12px 0px;
    position: relative;
  }
  .selectedCustomRadioAction {
    width: 100%;
    display: flex;
    border: 1px solid #f3f3f3;
    border-radius: 16px;
    padding: 6px;
    margin: 12px 0px;
  }
  .dataNotFoundSubWrapper {
    display: flex;
    justify-content: center;
  }
  .customRadioAction {
    width: 100%;
    display: flex;
    border: 1px solid #f3f3f3;
    border-radius: 16px;
    padding: 11.5px;
    margin: 12px 0px;
    position: relative;
  }
  .actionNameWrapper {
    display: flex;
    width: 100%;
    align-items: center;
  }
  .checkBoxChekedText {
    padding: 0px 8px;
    color: #434243;
    font-size: 14px;
    text-decoration: line-through;
  }
  .duplicateTextWrapper {
    justify-content: center;
    width: 56px;
    height: 28px;
    border-radius: 16px;
    background: #f9f9f9;
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .checkBoxIcon {
    width: 12px;
    height: 12px;
    margin: 0px 4px;
    cursor: pointer;
  }
  .schedualWrapper {
    display: flex;
    gap: 15px;
  }

