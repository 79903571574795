.dailyText {
    color: #434243;
    margin-bottom: 24px;
  }
  .WeeklyText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .DateweeklyText {
    color: #434243;
    margin-bottom: 24px;
  }
  .DayWrapper {
    width: 100%;
    display: flex;
    border: 1px solid #f3f3f3;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  .selectedDateText {
    width: 59.43px;
    min-width: 59.43px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #ffff;
    background-color: #9747ff;
    font-size: 14px;
  }
  .dateText {
    width: 59.43px;
    min-width: 59.43px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #434243;
    font-size: 14px;
  }