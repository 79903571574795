.radioWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin: 15px 0px 23px 0px;
  }
  .dailyText {
    color: #434243;
    margin-bottom: 24px;
  }
  .WeeklyText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .MonthlyText {
    color: #434243;
  }
  .DayWrapper {
    width: 100%;
    display: flex;
    border: 1px solid #f3f3f3;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  .dayText {
    width: 59px;
    min-width: 59px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    padding: 12px 0px;
    font-size: 14px;
    color: #434243;
  }

  .DateAndTimewrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;
    gap: 15px;
  }
  .backgroundChip {
    background: #f7f2fe;
    border-radius: 100px;
    color: #9747ff;
    height: 28px;
    cursor: pointer;
  }