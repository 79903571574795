.mainWrapper {
    width: 100%;
    padding: 12px;
    margin: auto;
  }
  .editInnerWrapper {
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
  }
  .DateAndTimewrapper {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .dateAndTimeText{
    cursor: pointer;
    color:  #190D1A;
  }
  .calanderWrapper {
    padding: 24px;
  }