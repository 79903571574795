.AccordianSummery {
  margin: 0px 0px !important;
  min-height: 48px !important;
}
.checkBoxText {
  padding: 0px 11px;
  display: flex;
  align-items: center;
  color: black;
}
.checkBoxText {
  padding: 0px 11px;
  display: flex;
  align-items: center;
  color: black;
}
.customRadio {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 14px 0px;
}
.subAccordianSummery {
  margin: 0px 0px !important;
  padding: 0px 1px !important;
  min-height: 20px !important;
}
.GrowthAccordianDetailContainer {
  padding: 0px !important;
}
.selectedWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.dataNotFoundSubWrapper {
  display: flex;
  justify-content: center;
}
