.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0px; */
}

.forgotPassword_btn_wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  margin-top: -4px;
}

.orText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color: #434243;
}

.imgIcon {
  cursor: pointer;
  width: 56px;
  height: 56px;
  background: #f9f9f9;
  border-radius: 12px;
  
  display: flex;
  align-items: center;
  justify-content: center;

}
