.StepFiveWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
    padding: 0 40px;
  }
  .StepFiveSubWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 16px auto;
    max-height: calc(100vh - 340px);
    overflow-x: auto;
  }
  .dailyText {
    color: #434243;
    margin-bottom: 24px;
  }
  .WeeklyText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .MonthlyText {
    color: #434243;
  }
  .DayWrapper {
    width: 100%;
    display: flex;
    border: 1px solid #f3f3f3;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  .dayText {
    width: 59px;
    min-width: 59px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    padding: 12px 0px;
    font-size: 14px;
    color: #434243;
  }
  .DateweeklyText {
    color: #434243;
    margin-bottom: 24px;
  }
  .selectedDayText {
    width: 59.43px;
    min-width: 59.43px;
    font-size: 14px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #ffff;
    background-color: #9747ff;
  }
  .selectedDateText {
    width: 59.43px;
    min-width: 59.43px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #ffff;
    background-color: #9747ff;
    font-size: 14px;
  }
  .dateText {
    width: 59.43px;
    min-width: 59.43px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #434243;
    font-size: 14px;
  }
  @media only screen and (max-width: 600px) {
    .dayText {
      width: 14.67%;
      min-width: 14.67%;
    }
    .selectedDayText {
      width: 11.67%;
      min-width: 11.67%;
    }
    .dateText {
      width: 11.67%;
      min-width: 11.67%;
    }
    .selectedDateText {
      width: 16.67%;
      min-width: 16.67%;
    }
  }
  .goalActionInput {
    margin: 12px 0px 32px 0px;
  }
  .goalActionInput
    .MuiInputBase-formControl:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #e5d5fc !important;
    border-width: 1px;
  }
  
  /* previous next button */
  
  .previousButton {
    padding: 18px 20px;
    width: 100%;
    border: 1px solid #9747ff;
    color: #9747ff;
    background: #f9f9f9;
    border-radius: 16px;
  }
  
  .previousButton:hover {
    border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff;
  }
  
  .previousButton:disabled {
    border: none !important;
  }
  
  .nextButtonGrp:disabled {
    background: #f9f9f9;
    border: none !important;
  }
  
  .nextButtonGrp {
    padding: 18px 20px;
    width: 100%;
    border-color: #9747ff;
    background: #9747ff;
    border-radius: 16px;
    color: #ffffff;
  }
  
  .nextButtonGrp:hover {
    border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff;
  }
  
  /* radio button */
  .radioWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin: 15px 0px 23px 0px;
  }
  /* save */
  .nextButton {
    width: 100%;
    max-width: 456px;
    border-color: #9747ff;
    background: #9747ff;
    border-radius: 16px;
    color: #ffffff;
    font-size: 1rem;
  }
  .nextButton:hover {
    border: 1px solid #a25bfe;
    color: #ffff;
    background: #a25bfe;
  }
  .nextButton:disabled {
    background: #f9f9f9;
    border: none !important;
  }
  .active {
    background: #9747ff;
    border: 1px solid #9747ff;
  }
  .progressBarWrapper {
    width: 100%;
    display: flex;
    margin-top: 24px;
    justify-content: center;
    gap: 8px;
  }
  .progressTittle {
    width: 123px;
    text-align: center;
    color: #000000;
  }
  .customActionLevelGroup {
    width: 100%;
    display: flex;
    align-items: center;
    height: 46px;
    margin: 12px 0;
    background-color: #F9F9F9;
  border-radius: 14px;
  }
  
  .customActionLevelGroup  button {
    width: 25%;
    border: none;
    gap: 5px;
    font-size: 0.85rem !important;
  border-radius: 14px !important;
  margin: 5px 3px;
  }
  .toggleButton {
    color: #190D1A;
    fill: #190D1A;
    stroke: #190D1A;
  
  }
  .selectedtoggleButton path {
    fill: #9747FF;
  }
  .selectedtoggleButton {
    color: #9747FF;
    fill: #9747FF;
    stroke: #9747FF;
    background-color: #fff !important
  
  }
  .toggleButton path {
    fill: #190D1A;
  }