.socialCardMainWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(276px, 1fr));
  gap: 12px;
  margin: auto;
  margin-top: 12px;
}
.socialGrid {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 16px;
  max-width: 100%;
}
.socialCardWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: "pointer";
  /* justify-content: space-between; */
}
.userIconeWrapper {
  width: 24px;
  height: 24px;
  background: #f7f2fe;
  border: 1px solid #ffffff;
  border-radius: 100px;
  width: 24px;
  height: 24px;
  padding: 4px;
}

