.stepOneWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.middleContent {
  margin-top: 20px;
}

.wrapper {
  width: 100%;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 10px 0px; */
  margin-top: 12px;
  border: 1px solid #fff;
}
.wrapper:hover {
  border: 1px solid #e5d5fc;
  border-radius: 16px;
}

.bottomContainerWrapper {
  width: 100%;
}

.addMoreQuestionBtn {
  margin-top: 12px;
  width: fit-content;
  padding: 8px 12px;
  background: #f7f2fe;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.addmoreTextWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .isAddMoreDisable{
  background: #fff;

} */

.questionListWrapper {
  /* background: blue; */
  max-height: 350px;
  overflow-y: auto;
  /* margin-top: 12px; */
}
