/* .leftSideWrapper{
  overflow-y: auto;
  height: 90vh;
}

.rightSideWrapper{
  overflow-y: auto;
  height: 90vh;
} */

.testListWrapper {
  margin-bottom: 18px;
}

.testInfo {
  /* min-height: 509px; */
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  margin-top: 0;
  margin-left: 0;
  padding-bottom: 20px;
}

.scoreInfo {
  padding: 24px;
  /* max-height: 204px; */
  background: #ffffff;
  border-radius: 20px;
}

.testDimensionsInfo {
  margin-bottom: 1rem;
  padding: 24px;
  /* min-height: 213px; */
  background: #ffffff;
  border-radius: 20px;
}

.dimensionImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

/* .calendarInfo {
  margin-top: 1rem;
  padding: 24px;
  /* min-height: 381px; */
/* background: #ffffff;
  border-radius: 20px; */
/* } */

/* *********************************************************** */

.iconsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 20px;

  /* background: blanchedalmond; */
}

.iconsLeftWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-width: 206px; */
}

.iconsRightWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 48px;
}

.goldCoinIconWrapper {
  width: max-content;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: #ffffff;
  border-radius: 100px;
  gap: 5px;
}

.goldCoinIconWrapper img {
  width: 12px;
  height: 12px;
}

.timeIcon {
  background: #ffffff;
  border-radius: 100px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionIcon {
  background: #ffffff;
  border-radius: 100px;
  padding: 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testName {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.testDescription {
  margin-top: 12px;
}

/* .readMore{
  color: #9747FF;
  cursor: pointer;
} */

.sourceText {
  margin-top: 1rem;
}

.testProfileWrapper {
  margin-top: 2rem;
}

.userName {
  margin-left: 16px;
}
/* ******************************************** */

.profileInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.profileInfoLeft {
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

/* 
.profileInfoRight{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* min-width: 376px; */
/* } */
/* */

.previous_btn {
  padding: 18px 20px;
  width: 182px;
  border: 1px solid #9747ff;
  color: #9747ff;
  border-radius: 16px;
}

.previous_btn:hover {
  border: 1px solid #9747ff;
  color: #ffffff;
  background: #9747ff;
}

.startTestButton {
  padding: 18px 20px;
  width: 182px;
  border: 1px solid #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
}

.startTestButton:hover {
  /* background: pink; */
  border: 1px solid #9747ff;
  color: #9747ff;
  background: #ffffff;
}

/* progess bar */

.cardProgressBar {
  margin-top: 2rem;
}
.progressBarWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  /* background: blue; */
}

.progress {
  height: 12px;
  border-radius: 1000px;
  background: #f7f2fe;
}

.progress span {
  border-radius: 100px;
}

/* dimensionsHeading css */

.dimensionsList {
  margin-top: 1rem;
}

.dimensionsHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dimensionsContentWrapper {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* background: yellow; */
}

.viewAndHideButton {
  width: 70px;
  margin-top: 24px;
  padding: 8px 12px;
  background: #e5d5fc;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* .scheduleButton {
  /* width: 70px; */
/* padding: 8px 12px;
  background: #e5d5fc;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; */
/* } */

/* .scheduleDataWrapper {
  background: #ffffff;
  border: 1px solid #e5d5fc;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
} */
/* .scheduleData {
  display: flex;
  align-items: center;
  gap: 8px;
} */

.scoreDescription {
  margin-top: 4px;
  border-color: #f7f2fe;
}

.divider {
  margin: 16px 0;
  border-color: #f7f2fe;
}

/* .calendarTitle {
  display: flex;
  flex-direction: column;
} */

/* .scheduleInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

.bookMarkIcon {
  /* margin-left: 8px; */
  display: flex;
  cursor: pointer;
}

.shareIcon {
  display: flex;
  cursor: pointer;
}

.testScores {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.testResultScore {
  margin-top: 2px;
  display: flex;
  gap: 25px;
  flex-wrap: wrap;
}

/* img css */

.imageWrapper {
  position: relative;
}

.imageWrapper img {
  width: 100%;
  max-height: 60vh;
  border-radius: 12px;
  object-fit: cover;
}

.ml4 {
  margin-left: 4px;
}
.mainWrapper {
  height: calc(100vh - 230px);
  /* height: calc(100vh - 370px); manasvi */
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
  /* margin-bottom: 16px; */
}
.testListWrapper {
  margin-bottom: 20px;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.reflectiveQuestions {
  padding: 24px;
  background: #ffffff;
  border-radius: 20px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.rQmainWrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 0;
  gap: 20px;
}
.breakLine {
  background: #f7f2fe;
  width: 100%;
  height: 1px;
}
/* css for personality result */
.PersonalityResultcontainer {
  /* padding-top: 3px; */
}
.personalityReslutItem {
  display: flex;
  margin: 6px 0px;
  padding: 6px 6px;
}
.personalityResultText {
  color: #434243;
  font-size: 10px;
  line-height: 14px;
  width: 45%;
}
.personalityResultpercentage {
  font-size: 10px;
  line-height: 14px;
  width: 10%;
  text-align: right;
}
.TestQuestionSlider {
  height: 10px;
  width: 45%;
}
.Imageoverlay{
  position: relative;

}
.Imageoverlay::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 99%;
  width: 100%; /* set to 100% for full overlay width */
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.3) 
    100%); 
  border-radius: 12px;
} 
