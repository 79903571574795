.Darg_drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  background: #ffffff;
  border-radius: 20px;
  overflow: auto;
}
.growth_Area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 25px 25px 10px 25px;
  gap: 20px;
}
.text_header {
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #190d1a;
  padding: 0px 18px 0px 0px;
}
.goals {
  /* height: 643px; */
  display: flex;
  align-items: flex-start;
  padding: 0px 22px 0px 0px;
  /* gap: 15px; */
  overflow: auto;
}
.Featured_goals {
  padding-left: 20px;
  padding-top: 15px;
}
.goals_featured,
.goals_favourite {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 22px;
  gap: 10px;
  width: 100%;
}
.goals_favourite{
  margin-top: 15px;
}
.closebutton{
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nextButton {
  padding: 5px;
  width: 100%;
  /* border: 1px solid #9747FF; */
  /* border-color: #9747ff; */
  /* background: #9747ff; */
  border-radius: 16px;
  color: #ffffff;
}