.mainWrapper {
  height: calc(100vh - 230px);
  /* height: calc(100vh - 370px); manasvi */
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* margin-bottom: 16px; */
  position: relative;
}
.indivdualWrapper {
  height: calc(100vh - 140px);
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* margin-bottom: 16px; */
  position: relative;
}
.headerWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.footerWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 8px;
}
.AvgPrnWrapper {
  display: flex;
}
.AvgPopulationBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.PersPopulationBtn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.MuiPickerStaticWrapper-content > div > div {
  display: none;
}

/* date range picker */
.startEndWrapper {
  display: flex;
  margin: auto;
  margin-top: 24px;
  /* width: 300px; */
}
.startWrapper {
  /* border: 1px solid #a25bfe; */
  /* border-radius: 16px;
  padding: 16px;
  margin-right: 6px; */
}
.endWrapper {
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 16px;
  margin-left: 6px;
}
.periodLabel {
  font-size: 10px;
  line-height: 140%;
  color: #434243;
  /* text-align: center;
  margin: 2px auto; */
}
.period {
  font-size: 14px;
  color: #190d1a;
}

/* social individul  */
.socialCardMainWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(276px, 1fr));
  gap: 12px;
  margin: auto;
  margin-top: 12px;
}
.socialGrid {
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  padding: 16px;
  max-width: 100%;
}
.socialCardWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: "pointer";
  /* justify-content: space-between; */
}
.userIconeWrapper {
  background: #f7f2fe;
  border: 1px solid #ffffff;
  border-radius: 100px;
  width: 28px;
  height: 28px;
  padding: 4px;
}
.dateTimeRangePickerSubWrapper {
  width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  height: 48px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  /* padding: 16px;   */
}
.dateTimeRangePickerWrapper {
  /* width: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
  margin-right: 10px;
  height: 64px;
  width: 110px;
}