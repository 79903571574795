.mainWrapper {
  width: 100%;
  height: 100%;
  padding: 24px;
  overflow-y: auto;
}
.closeIconeWrapper{
  position: absolute;
  right: 20px;
  top: 20px;
  background: #FFFFFF;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circularprogressBar {
  margin: 6px !important;
}
.ContentWrapper {
  margin: 6px;
  overflow-x: hidden;
}
.ZestHeading {
  padding-top: 8px;
  padding-bottom: 6px;
}
.ContentText {
  color: #434243;
  font-weight: 400;
  padding-top: 6px;
  line-height: 1.5rem;
  font-size: 16px;
  padding-bottom: 3px;
}
.QauetionText {
  color: #190d1a;
  font-weight: 400;
  padding: 5px 0px;
  margin-top: 8px;
}
.RadioOptionText {
  color: #434243;
  font-weight: 400;
  /* word-break: break-all; */
  padding: 0.5px 0px;
  align-items: center;
  text-align: start;
  border: 1px solid #F3F3F3;
  width: 100%;
  border-radius: 16px;
  margin: 8px 0;
  padding: 16px;
}
.resultSliderWrapper {
  max-width: 100%;
}
.reviewButton {
  max-width: 100% !important;
}
.CoinButton {
  width: 20px;
  height: 20px;
  margin: auto 10px;
}
@media screen and (max-width: 1200px) {
  .mainWrapper {
    /* overflow-y: hidden; */
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  .mainWrapper {
    padding: 10px 0px;
  }
}
.actionLable {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.backgroundChip {
  background: #f7f2fe;
  border-radius: 100px;
  color: #9747ff;
  height: 20px;
}
.backgroundChip .MuiChip-avatar {
  margin-right: -16px;
  margin-left: 5px;
}
.backgroundChip img {
  height: 12px;
  width: 12px;
}
.backgroundChip span {
  font-size: 0.75rem;
}