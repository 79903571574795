.categoryHeading {
  /* border: 1px solid black; */
  /* height: 228px; */
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
}

.categoryDescription {
  margin-top: 12px;
  /* width: 28.5rem; */
  /* max-width: 28.5rem; */
}

.categoryDescription ol{
  margin-left: 40px;
}

.categoryLabels {
  margin-top: 4px;
}
.badgesWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* background: yellow; */
}

.SearchBar {
  /* background: yellow; */
  margin-top: 12px;
  margin-bottom: -4px;
}


.testListWrapper {
  margin-top: 4px;
  margin-bottom: 20px;
}

/* card css */

.description span{
  color: #434243 !important;
}

.description img{
  width: 100%;
  object-fit: cover;
}
