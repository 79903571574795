
.wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    height: 100%;
}
.headerWrapper{
    text-align: center;
}


.buttonWrapper{
    width: 100%;
}

.textInput{
    margin-top: 12px;
    width: 100%;
}

.middelContent{
    margin-top: 39px;
}

.wordCount{
    margin-top: 12px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}