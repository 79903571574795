.main_wrapper {
  /* background: rgba(254, 248, 243, 1); */
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.main_GAC {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  gap: 14px;
}
.Darg_drop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  background: #ffffff;
  border-radius: 20px;
  overflow: auto;
}
.calendar {
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 0px;
  gap: 20px;
  background: #ffffff;
  border-radius: 20px;
  overflow: auto;
  width: 100%;
}
.growth_Area {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 25px 25px 10px 25px;
  gap: 20px;
  width: 330px;
}
.goals {
  width: 338px;
  height: 643px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 22px 0px 0px;
  gap: 15px;
  overflow: auto;
}
.goals_featured,
.goals_favourite {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 262px;
  height: 22px;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
  gap: 8px;
  margin-left: 0px;
}
.Featured_goals {
  padding-left: 20px;
}
.AccordianSummery {
  margin: 0px 0px !important;
  min-height: 48px !important;
}
.checkBoxText {
  padding: 0px 11px;
  display: flex;
  align-items: center;
  color: black;
}
.customRadio {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  justify-content: space-between;
  padding: 16px;
  margin: 12px 0px;
}
.checkBoxText {
  padding: 0px 11px;
  display: flex;
  align-items: center;
  color: black;
}
.customRadio {
  width: 100%;
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  justify-content: space-between;
  padding: 16px;
  margin: 12px 0px;
}
.showOpenGrid {
  margin-left: 5px !important;
  padding: 12px 40px !important;
}