.action_data {
    border-radius: 4px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 6px;
    gap: 6px;
  }
  .textAction {
    width: 67px;
    height: 40px;
    overflow: hidden;
    padding-right: 10px;
    display: flex;
    align-items: center;
    line-height: 12px;
    font-size: 13px;
  }
  .action_box {
    display: flex;
    flex-direction: column;
    gap: 15px;
    /* padding: 15px; */
    /* justify-content: center; */
    align-items: center;
  }