.DateAndTimewrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 15px;
  gap: 15px;
}
.dailyText {
  color: #434243;
  margin-bottom: 24px;
}
.WeeklyText {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.radioWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  margin: 15px 0px 23px 0px;
}


