/* 
.wrapperDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    height: 100%;
  } */
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }