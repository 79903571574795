.closebutton{
    position: absolute;
    right: 15px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    background: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
}