.fieldName {
  margin: 8px 0px 12px 0px;
}

.backbutton {
  margin: 0px 15px -3px 0px;
}
.tableHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 16px;
  gap: 32px;

  width: 840px;
  height: 46px;

  /* Main/Background */

  background: #f9f9f9;
  border-radius: 16px 16px 0px 0px;
}

.tableHeader {
  width: 100%;
}
.days_header {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  align-items: center;

  /* Main/Black */

  color: #190d1a;
  border: none;
}

.days_data {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  /* identical to box height, or 14px */

  align-items: center;
  letter-spacing: -0.01em;

  /* Main/Dark Gray */

  color: #434243;
  border: none;
}

.headerTitle {
    font-weight: 600;
font-size: 28px;
line-height: 120%;
/* identical to box height, or 34px */

letter-spacing: -0.01em;

/* Main/Black */

color: #190D1A;

}

.tbale {
    margin-top: 32px;
}