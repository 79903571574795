.calendarInfo {
  /* min-height: 381px; */
  background: #ffffff;
  margin-top: -20px;
}

.calendarTitle {
  display: flex;
  flex-direction: column;
}

.scheduleInfoWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 13px;
}

.scheduleButton {
  /* width: 70px; */
  padding: 8px 12px;
  background: #e5d5fc;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.scheduleDataWrapper {
  background: #ffffff;
  border: 1px solid #e5d5fc;
  border-radius: 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.scheduleData {
  display: flex;
  align-items: center;
  gap: 8px;
}
