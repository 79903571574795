.progressBarWrapper{
    display: flex;
    align-items: center;
    width: 100%;
    /* background: blue; */
}

.progress{
    height: 12px;
    border-radius: 100px;
    background: #E5D5FC;
}

.progress span{
    border-radius: 100px;
}