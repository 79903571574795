.wrapperDiv {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.buttonWrapper {
  width: 100%;
}

/* acco css */

.progressValue {
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headingWrapper {
  display: flex;
  align-items: center;
  gap: 12px;
}

.title {
  margin-bottom: 32px;
}
.accordionContent{
height: 85%;
overflow-y: scroll;
}

.accordionWrapper {
  margin-top: 12px;
}
.accordin{
  border: 1px solid #F3F3F3;
  border-radius: 16px !important;
  position: "relative";
}
.upperContent {
  height: 90%;
}

.questions{
  display: flex;
  gap: 8px;
  align-items: center;
  margin-top: 8px;
}
.questions img{
  width: 16px;
height: 16px;
}

.progressBarWrapper{
  margin-top: 24px;
}
/* other */

.accordionSummary > div {
  flex-direction: column;
  gap: 23px;
}
.accordionSummary {
  position: relative;
}
.accordionSummary > div:nth-child(2) {
  position: absolute;
  top: 15px;
  right: 15px;
}

