  .StepFiveWrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    position: relative;
  }
  .StepFiveSubWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    max-height: 470px;
  }
  .radioWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin: 15px 0px 23px 0px;
  }
  .dailyText {
    color: #434243;
    margin-bottom: 24px;
  }
  .WeeklyText {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }
  .MonthlyText {
    color: #434243;
  }
  .DayWrapper {
    width: 100%;
    display: flex;
    border: 1px solid #f3f3f3;
    margin-bottom: 24px;
    flex-wrap: wrap;
  }
  .dayText {
    width: 59px;
    min-width: 59px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    padding: 12px 0px;
    font-size: 14px;
    color: #434243;
  }
  .DateweeklyText {
    color: #434243;
    margin-bottom: 24px;
  }
  .selectedDayText {
    width: 59.43px;
    min-width: 59.43px;
    font-size: 14px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #ffff;
    background-color: #9747ff;
  }
  .selectedDateText {
    width: 59.43px;
    min-width: 59.43px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #ffff;
    background-color: #9747ff;
    font-size: 14px;
  }
  .dateText {
    width: 59.43px;
    min-width: 59.43px;
    text-align: center;
    border-right: 1px solid #f3f3f3;
    border-bottom: 1px solid #f3f3f3;
    padding: 12px 0px;
    color: #434243;
    font-size: 14px;
  }
  @media only screen and (max-width: 600px) {
    .dayText {
      width: 14.67%;
      min-width: 14.67%;
    }
    .selectedDayText {
      width: 11.67%;
      min-width: 11.67%;
    }
    .dateText {
      width: 11.67%;
      min-width: 11.67%;
    }
    .selectedDateText {
      width: 16.67%;
      min-width: 16.67%;
    }
  }
  .goalActionInput {
    margin: 12px 0px 32px 0px;
  }
  .goalActionInput
    .MuiInputBase-formControl:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: #e5d5fc !important;
    border-width: 1px;
  }

.customActionLevelGroup {
  width: 100%;
  display: flex;
  align-items: center;
  height: 46px;
  margin: 12px 0 16px 0;
  background-color: #F9F9F9;
  border-radius: 14px;
}

.customActionLevelGroup  button {
  width: 25%;
  border: none;
  gap: 5px;
  font-size: 0.85rem !important;
  border-radius: 14px !important;
  margin: 5px 3px;
}
.toggleButton {
  color: #190D1A;
  fill: #190D1A;
  stroke: #190D1A;

}
.selectedtoggleButton path {
  fill: #9747FF;
}
.selectedtoggleButton {
  color: #9747FF;
  fill: #9747FF;
  stroke: #9747FF;
  background-color: #fff !important

}
.toggleButton path {
  fill: #190D1A;
}