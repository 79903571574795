.main_div {
  margin: 12px !important;
  background-color: #ffffff;
  border-radius: 20px;
  width: 95%;
}
.header {
  padding: 16px;
}
.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;
}