.SnackbarWrapper {
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: center;
  height: 120px;
}
.dataNotFoundSubWrapper {
  display: flex;
  justify-content: center;
  text-align: center;
}
