.dashbordTitleContainer {
  display: flex;
  flex-direction: column;
  background-color: #ffff;
  padding: 24px;
  border-radius: 20px;
  gap: 12px;
}

.dashbordTitle {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.dashbordTitlebody {
  display: flex;
  flex-direction: column;
}
.mainWrapper {
  min-height: calc(100vh - 230px);
  /* height: calc(100vh - 370px); manasvi */
  overflow: auto;
  margin-top: 16px;
  background-color: #ffffff;
  padding: 24px;
  border-radius: 20px;
  overflow-y: auto;
  /* margin-bottom: 16px; */
}
.testListWrapper {
  margin-bottom: 20px;
}

.progressBarWrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
.progress {
  height: 12px;
  border-radius: 100px;
  background: #f7f2fe;
}

.progress span {
  border-radius: 100px;
}

.cardHeader {
  margin-top: 12px;
}

.card {
  height: 296px;
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageWrapper img {
  width: 100%;
  max-height: 96px;
  border-radius: 12px;
}
/* css for personality result */
.PersonalityResultcontainer {
  /* padding-top: 3px; */
}
.personalityReslutItem {
  display: flex;
  border: 1px solid #f3f3f3;
  border-radius: 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  gap: 16px;
  min-height: 52px;
  height: 52px;
}
.personalityResultText {
  color: #434243;
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 14px;
  width: 45%;
}
.personalityResultpercentage {
  font-size: 10px;
  line-height: 14px;
  width: 10%;
  color: #190D1A;
  text-align: right;
}
.TestQuestionSlider {
  height: 10px;
  width: 45%;
}
/* social card css */
.headerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  flex-wrap: wrap;
}
.socialCardMainWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(276px, 1fr));
  gap: 12px;
  margin-left: 0px;
}
