.main_box {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 145%;
  letter-spacing: -0.01em;
  color: #190d1a;
}
.divider_box {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 14px 0px 14px 3px;
  gap: 10px;
  /* width: 216px; */
  /* height: 25px; */
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #434243;
  justify-content: space-between;
  cursor: pointer;
}
.hr_line {
  border: 1px solid #f7f2fe;
  width: 100%;
}
.DateAndTimewrapper {
  width: 271px;
  height: 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  gap: 10px;
}
.calanderWrapper {
  padding: 24px;
}
.dateTimeRangePickerWrapper {
  width: 100%;
  display: flex;
  gap: 8px;
}
.dateTimeRangePickerSubWrapper {
  width: 100%;
  border: 1px solid #f3f3f3;
  border-radius: 8px;
  height: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.DateAndTimewrapper {
  display: flex;
  gap: 10px;
  padding-bottom: 16px;
}
.boxDuration {
  width: 336px;
  /* height: 383px;  */
  border: 1px solid #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 16px;
  padding: 16px;
  gap: 14px;
}
.setTimeBox {
  width: 304px;
  height: 32px;
  background: #f9f9f9;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 2px;
}
.time_based_event {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  width: 150px;
  height: 28px;
  background: #ffffff;
  box-shadow: 0px 0px 50px rgba(25, 13, 26, 0.08);
  border-radius: 8px;
}
.text_size {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: -0.01em;
}
.unspecified {
  display: flex;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 304px;
  height: 70px;
  margin-bottom: 10px;
}
.radioWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  margin: 15px 0px 23px 0px;
  font-size: 14px;
}
.radioWrapper_un{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 44px;
  font-size: 14px;
}