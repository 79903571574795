.mainWrapper{
  
    width: 100%;
    padding: 12px;
    margin: auto;
}
.editInnerWrapper{
    display: flex;
    flex-direction: column;
    gap: 8px;

}
.editText{
    color: #434243;
    cursor: pointer;
}