.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: '16px';
  }
  .stepsCount{
    display: flex;
    width: 100%;
    gap: 8px;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 48px;
  }
  .step{
    width: 108px;
    height: 12px;
    border: 1px solid #E5D5FC;
    border-radius: 10px;
    background: #E5D5FC;
  }

  .active{
    background: #9747FF;
    border: 1px solid #9747FF;
  }