.nextButton {
  /* padding: 18px 20px; */
  width: 100%;
  max-width: 456px;
  border-color: #9747ff;
  background: #9747ff;
  border-radius: 16px;
  color: #ffffff;
  font-size: 1rem;
}
.nextButton:hover {
  /* background: pink; */
  border: 1px solid #A25BFE;
  color: #ffff;
  background: #A25BFE;
}
.nextButton:disabled {
  background: #f9f9f9;
  border: none !important;
}
.reviewButton {
  width: 100%;
  border-color: #9747ff;
  background: #ffffff;
  border-radius: 16px;
  color: #9747ff;
  font-size: 1rem;
}
.reviewButton:hover {
  background: #9747ff;
  color: #ffffff;
}
