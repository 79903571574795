* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Inter", sans-serif;
}
::-webkit-scrollbar {
  width: 0px;
  border-radius: 20px;
}
/* div ::-webkit-scrollbar-track {
  background: #ebf2f1;
  border-radius: 10px;
}

::-webkit-scrollbar {
  overflow: auto;
  width: 4px;
  height: 4px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color:  #9747FF;
}

::-webkit-scrollbar-thumb:hover {
  background:  #9747FF;
} */
.MuiPickerStaticWrapper-content > div > div:nth-child(1) {
  display: none;
}

.highcharts-label-box {
  x: 0;
  y: 0;
}

.highcharts-legend-item.highcharts-series-0 > .highcharts-point {
  fill: #9747ff;
}
.highcharts-legend-item.highcharts-series-1 > .highcharts-point {
  fill: #ef8b6e;
}
.highcharts-series.highcharts-series-1.highcharts-column-series
  > .highcharts-point {
  fill: #f5ebe8;
}

.personalChart .highcharts-series > .highcharts-point {
  fill: #ffffff;
  width: 0px;
}
.highcharts-axis-labels .highcharts-xaxis-labels text {
  color: green !important;
  fill: green !important;
  /* fill: #434243; */
}
/* .highcharts-scrolling{
  background: #9747ff;
  height: 0PX;
} */
.picker-container{
  height: 140px;
}
.text-3lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-1lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}