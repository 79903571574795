/* .wrapperDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    width: 100%;
    flex-direction: column;
    height: 100%;
  } */
.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 16px; */
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
.AgeSelectionRadioButton {
  border: 1px solid #fff;
  border-radius: 16px;
  margin-top: 16px;
  margin-left: 12px;
}
.AgeSelectionRadioButton :hover {
  border: 1px solid #9747ff;
}
