.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px 0px; */
}


.orText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  color:  #434243;
}

/* .terms_and_condition{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -2px;
  margin-left: 3px;

  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 140%;
} */
.terms_and_condition span {
  padding: 0;
  /* width: 20px;
  height: 20px; */
}
.imgIcon {
  cursor: pointer;
  width: 56px;
  height: 56px;
  background: #f9f9f9;
  border-radius: 12px;
  
  display: flex;
  align-items: center;
  justify-content: center;

}

