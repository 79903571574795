.forgotPassword_btn {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}

.submit_btn {
  height: 56px;
  width: 100%;
  padding: 18px 0;
  border-radius: 16px;
  text-transform: capitalize;
  box-shadow: none;
}

.submitButtonOutline {
  width: 100%;
  padding: 20px 0;
  border-radius: 16px;
  color: #9747ff;
  background: #f9f9f9;
  border-color: #9747ff;
  max-height: 56px;
}

.submitButtonOutline:hover {
  color: #f9f9f9;
  background: #9747ff;
  border-color: #9747ff;
}

.submitButtonOutlineWithBackGround {
  width: 100%;
  padding: 20px 0;
  border-radius: 16px;
  color: #f9f9f9;
  background: #9747ff;
  border-color: #9747ff;
  max-height: 56px;
}
/* submitButtonOutlineWithBackGround */
.submitButtonOutlineWithBackGround:disabled {
  background: #f9f9f9;
  border: none !important;
}
.submitButtonOutlineWithBackGround:hover {
  color: #ffffff;
  background: #9747ff;
  border-color: #9747ff;
}

.signup_btn {
  color: #000000;
  justify-content: flex-start;
  margin-left: 10px;
}

.signup_btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #434243;
}

.previous_btn {
  width: 100%;
  padding: 20px 0;
  border-radius: 16px;
  color: #9747ff;
  background: #f9f9f9;
  border-color: #9747ff;
}

.next_btn {
  width: 100%;
  padding: 20px 0;
  border-radius: 16px;
  color: #dad6da;
  background: #f9f9f9;
  border-color: #f9f9f9;
}

.next_btn:hover {
  border-color: #9747ff;
  color: #9747ff;
}
.unlockButtonOutline {
  width: 100%;
  padding: 18px 20px;
  border-radius: 100px;
  color: #9747ff;
  background: #f9f9f9;
  border-color: #9747ff;
  max-height: 40px;
}

.unlockButtonOutline:hover {
  color: #f9f9f9;
  background: #9747ff;
  border-color: #9747ff;
}

.unlockButtonOutlineWithBackGround {
  width: 100%;
  padding: 20px 0;
  border-radius: 16px;
  color: #f9f9f9;
  background: #9747ff;
  border-color: #9747ff;
  max-height: 56px;
}
/* submitButtonOutlineWithBackGround */
.unlockButtonOutlineWithBackGround:disabled {
  background: #f9f9f9;
  border: none !important;
}
.unlockButtonOutlineWithBackGround:hover {
  color: #9747ff;
  background: #f9f9f9;
  border-color: #9747ff;
}
.NextButtonWithOutlineBackGround {
  width: 89%;
  padding: 16px;
  border-radius: 16px;
  color: #f9f9f9;
  background: #9747ff;
  border-color: #9747ff;
  max-height: 46px;
}
/* submitButtonOutlineWithBackGround */
.NextButtonWithOutlineBackGround:disabled {
  background: #f9f9f9;
  border: none !important;
}
.NextButtonWithOutlineBackGround:hover {
  color: #ffffff;
  background: #9747ff;
  border-color: #9747ff;
}
.settingUploadButton {
  padding: 16px;
  border-radius: 8px;
  color: #f9f9f9;
  background: #9747ff;
  border-color: #9747ff;
  max-height: 40px;
}
.settingUploadButton:disabled {
  background: #f9f9f9;
  border: none !important;
}
.settingDeleteButton {
  padding: 16px;
  border-radius: 8px;
  color: #f9f9f9;
  background: #9747ff;
  border-color: #9747ff;
  max-height: 40px;
}
.settingDeleteButton:disabled {
  background: #f9f9f9;
  border: none !important;
  color: #000000;
  font-weight: 400;
}
