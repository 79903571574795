.wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 16px; */
  }
  .active{
      background: #9747FF;
      border: 1px solid #9747FF;
    }