.emptybox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 42px 186px;
  gap: 16px;

  position: relative;
  width: 100%;
  /* Main/White */

  background: #ffffff;
}
.emptyContentBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 40px;

  width: 100%;
}

.emptyActionButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px;
  gap: 16px;
  isolation: isolate;

  width: 100%;
}

.emptyTextBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 100%;
}
.graphicalBox {
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.illubox {
  width: 768px;
  height: 248px;
  position: relative;
  /* Main/White */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 120px;
  background: #ffffff;
}

.testCard {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 13.2171px;
  gap: 10.57px;

  position: relative;
  width: 201.69px;
  height: 232.27px;
  /* left: 56.72px;
  top: 28.65px; */

  /* Main/White */

  background: #ffffff;
  border-radius: 13.2171px;
  transform: rotate(-2deg);
  border: 2.64px solid;

  /* border-image-source: linear-gradient(
    135.69deg,
    rgba(241, 237, 255, 0.5) 3.39%,
    rgba(213, 202, 251, 0.5) 107.13%
  ); */
  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(
      135.69deg,
      rgba(241, 237, 255, 0.5) 3.39%,
      rgba(213, 202, 251, 0.5) 107.13%
    );
 /*  border: 2.64px solid;

  border-image-source: linear-gradient(
    135.69deg,
    rgba(241, 237, 255, 0.5) 3.39%,
    rgba(213, 202, 251, 0.5) 107.13%
  ); */
  border: 3px solid rgba(213, 202, 251, 0.5);
}
.cardProgressBar {
  width: 100%;
}
.resultCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 13.2171px;
  gap: 19.36px;

  position: relative;
  width: 200.9px;
  height: 219.4px;
  /* left: 507.35px;
  top: 15px; */

  /* Main/White */

  background: #ffffff;
  border-radius: 13.2171px;
  transform: rotate(2deg);
  border: 3px solid rgba(213, 202, 251, 0.5);
}
.labelImageBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8.25px;

  width: 175.25px;
  height: 178.77px;
}
.imageBar {
  width: 174.93px;
  height: 103.28px;

  border-radius: 8.24722px;
}

.imageClss {
  position: relative;
  width: 174.93px;
  height: 103.28px;
  left: 0px;
  top: 3.5px;
  object-fit: cover;
  background: transparent;
}

.imageClss img {
  width: 175.93px;
  height: 105.28px;
  object-fit: cover;
  transform: rotate(2deg);
}

.testbar {
  position: absolute;
  width: 162.88px;
  height: 10.31px;
  left: 16px;
  top: 16px;
}

.tags {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 3.61px;

  position: absolute;
  width: 142.56px;
  height: 16.12px;
  left: 2px;
  top: 8px;
}

.tagcoin {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2.0618px 4.12361px;
  gap: 2.06px;

  height: 16.12px;

  /* Main/White */

  background: #ffffff;
  border-radius: 51.5451px;
}

.tagcoin img {
  height: 12px;
  width: 12px;
}

.tagdimond {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2.0618px 4.12361px;
  gap: 2.06px;

  height: 16.12px;

  /* Main/White */

  background: #ffffff;
  border-radius: 51.5451px;
}
.tagdimond img {
  height: 12px;
  width: 12px;
}

.tagtime {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 2.0618px 4.12361px;
  gap: 2.06px;
  height: 16.12px;

  /* Main/White */

  background: #ffffff;
  border-radius: 51.5451px;
}

.testText {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4.12px;

  width: 175.25px;
  height: 67.24px;
}

.progressBarWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 5px;
  /* background: blue; */
}

.progress {
  height: 6.19px;
  border-radius: 100px;
  background: #f7f2fe;
}

.progress span {
  border-radius: 100px;
}

.unlockResults {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7.94821px;
  gap: 4.97px;

  position: absolute;
  height: 35.77px;
  /* left: 400.37px;
  top: 180.19px; */

  /* Gradient/Purple */

  background: linear-gradient(135.69deg, #f1edff 3.39%, #d5cafb 107.13%);
  border: 3.97411px solid rgba(255, 255, 255, 0.4);
  box-shadow: 15.8964px 15.8964px 31.7928px rgba(25, 13, 26, 0.1);
  border-radius: 7.94821px;
  transform: rotate(-1deg);
  left: -90px;
  bottom: 15px;
}

.numberbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 9.93526px;
  gap: 9.94px;

  width: 19.87px;
  height: 19.87px;

  background: #ffffff;
  border-radius: 99.3526px;
  transform: rotate(2deg);
  box-shadow: 14.817249298095703px 14.817249298095703px 29.634498596191406px 0px
    rgba(25, 13, 26, 0.1);
  border: 3.7px solid rgba(255, 255, 255, 0.4);
}
.takeresult {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7.40862px;
  gap: 4.63px;

  position: absolute;
  height: 33.34px;
  right: -100px;
  top: 10px;

  /* Gradient/Purple */

  background: linear-gradient(135.69deg, #f1edff 3.39%, #d5cafb 107.13%);
  border: 3.70431px solid rgba(255, 255, 255, 0.4);
  box-shadow: 14.8172px 14.8172px 29.6345px rgba(25, 13, 26, 0.1);
  border-radius: 7.40862px;
  transform: rotate(1deg);
  box-shadow: 14.817249298095703px 14.817249298095703px 29.634498596191406px 0px
    rgba(25, 13, 26, 0.1);
  border: 3.7px solid rgba(255, 255, 255, 0.4);
}
.lockIcon {
  position: relative;
  width: 52.87px;
  height: 52.87px;
  /* left: calc(50% - 52.87px/2 + 0.43px);
top: calc(50% - 52.87px/2 + 0.43px); */
}

.maincontainerWrapper {
  width: 100%;
  height: 240px;
  background: #ffffff;
  border-radius: 16px;
  display: flex;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.headerWrapper {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}
.headerTitleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}
.ScoreHighLowWrapper {
  margin-top: 4px;
  display: flex;
  justify-content: space-between;
}