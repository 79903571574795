.invitePageWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }
  .closeIconeWrapper{
    position: fixed;
    right: 20px;
    top: 20px;
    background: #FFFFFF;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .headingWrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin: 0px 30px;
  }
  .nextButton {
    width: 100%;
  }
  .headingText {
    text-align: center;
  }
  
  
  .subHeadingText {
    /* width: 85%; */
    text-align: center;
  }
  
  .coinIconWrapper {
    width: fit-content;
    height: 20px;
    background: #f7f2fe;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    margin-top: 8px;
  }
  
  .coinIconWrapper img {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  