.wrapper {
  font-family: "inter";
  font-size: 18px;
  color: #9747ff;
  letter-spacing: -1%;
  line-height: 26.1px;
  margin-bottom: 100px;
}
.code_title {
  font-family: "inter";
  font-size: 38px;
  font-weight: 700;
  color: #190d1a;
  letter-spacing: -1%;
  line-height: 115%;
  text-align: center;
}
.email_title {
  font-family: "inter";
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -1%;
  color: #b4b4b4;
}
.set_btn {
  background-color: #9747ff !important;
  width: 456px;
  height: 56px;
  padding: 19px 20px;
  color: #ffffff;
  border-radius: 12px !important;
}
.set_text {
  font-family: "inter";
  font-size: 16px;
  letter-spacing: -2%;
}
.set_text span {
  color: #9747ff;
}
.inputWrapper{
    margin-bottom: 100px;
}
.se_inputField {
  display: flex;

}
.box {
  display: "flex";
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 64px;
  text-align: center !important;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #f3f3f3;
  outline: none;
  font-size: 24px;
  margin-left: 16px;
}
.box:hover{
      border: 1px solid #e5d5fc;
}
.box:focus{
 border: 1px solid #9747ff;
}
.setError{
  margin-left: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  font-family: 'enter';
  font-size: 14px;
  margin-top: 12px;
  color: #EF8B6E;
}
.errorColor{
  display: "flex";
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 64px;
  text-align: center !important;
  font-weight: 700;
  border-radius: 10px;
  border: 1px solid #EF8B6E;
  color: #EF8B6E;
  outline: none;
  font-size: 24px;
  margin-left: 16px;
}
