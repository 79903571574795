.main_wrapper {
  background-image: linear-gradient(
    180deg,
    rgba(229, 213, 252, 0.3),
    rgba(255, 209, 114, 0.3)
  );
}

.invitedPersonName {
  background-color: aqua;
  position: absolute;
  min-width: 220px;
  min-height: 149px;
  left: 20px;
  top: 40px;
  background: #ffffff;
  border-radius: 16px;
  padding: 16px;
}

.divider {
  margin: 16px 0;
  border-color: #f7f2fe;
  background: #f7f2fe;
}

.feedBackType {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #9747ff;
  margin-right: 8px;
}

.layoutWrapper {
  /* background-color: yellow; */
}

.invitedPersonNameMobileDevice {
  display: flex;
  /* background-color: aqua; */
  width: 100%;
  justify-content: space-evenly;
  margin-bottom: 12px;
}

.personName {
  background: #ffffff;
  border-radius: 16px;
  min-height: 54px;
}

.personNameMobile{
  background: #ffffff;
  border-radius: 16px;
  min-height: 54px;
  padding: 12px;
  width: 171px;
}

.feedBackTypeWrapper {
  background: #ffffff;
  border-radius: 16px;
  min-height: 54px;
}

.feedBackTypeWrapperMobile{
  background: #ffffff;
  border-radius: 16px;
  min-height: 54px;
  padding: 12px;
  width: 171px;
}