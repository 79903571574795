.wrapper {
  width: 100%;
  text-align: "center";
}
.wrapperDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.active {
  background: #9747ff;
  border: 1px solid #9747ff;
}
