.inviteInfoPopup {
  /* position: relative;
    width: 1440px;
    height: 820px;
    left: 3720px;
    top: 640px;
     */
}

.inviteCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 40px;
  gap: 16px;
  isolation: isolate;

  position: absolute;
  width: 400px;
  height: 192px;
  /* left: 80px;
    top: 10px; */

  /* Main/Purple */

  background: #9747ff;
  border: 10px solid rgba(255, 255, 255, 0.4);
  /* box-shadow: 0px 2px 0px 10px rgba(255, 255, 255, 0.4); */
  border-radius: 32px;
  align-self: center;
  z-index: 2;
}

.invitePopupInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 140px 40px 40px;
  gap: 32px;

  position: absolute;
  width: 100%;
  /* height: 620px; */
  left: 0px;
  top: 74px;

  /* Main/White */

  background: #ffffff;
  border-radius: 32px;
  z-index: 1;
}
.premiumText {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;
  gap: 12.82px;
  /* width: 67px; */
  /* height: 28px; */
  background: #ffffff;
  border-radius: 7px;
  color: #9747ff;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
}

.premiumScientific {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;
}

.premiumScientific2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */

  text-align: center;

  /* Main/White */

  color: #ffffff;
}

.dataContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  width: 100%;
}
.dataContentTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}
.dataContentStepper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 20px;
  isolation: isolate;
  width: 100%;
  position: relative;
}
.stepperItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 16px;

  width: 480px;
  height: 50px;
}

.stepperCounter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  gap: 10px;

  width: 28px;
  height: 28px;

  /* Main/Purple */

  background: #9747ff;
  border-radius: 100px;
  z-index: 2;
}
.stepperCounterCount {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 100%;
  /* or 20px */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.01em;

  /* Main/White */

  color: #ffffff;
}

.stepperContebnt {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 4px;

  width: 436px;
  height: 50px;
}

.stepperContebntTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */

  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}
.stepperContebntTitle1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  display: flex;
  align-items: center;
  letter-spacing: -0.01em;

  /* Main/Dark Gray */

  color: #434243;
}
.inviteBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;

  /* Main/White */

  background: #ffffff;
}
.inviteBlockTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 145%;
  /* or 26px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}
.CTAButtonGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;

  width: 152px;
  height: 88px;
}
.CTAButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 12px;

  width: 64px;
  height: 88px;
  cursor: pointer;
}

.CTAIcon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 64px;
  height: 64px;

  background: linear-gradient(135.76deg, #ddf4ff 0%, #bbe8fb 103.63%);
  border-radius: 100px;
}

.CTAText {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  /* identical to box height, or 12px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}
.inviteButton {
  width: 100%;
  border-radius: 12px;
  font-size: 1rem;
}
.inviteButton:disabled {
  background: #f9f9f9;
  border: none !important;
  color: rgba(0, 0, 0, 0.26);
}
.closeSection {
  position: absolute;
  top: 5px;
  right: 20px;
  cursor: pointer;
}

.placeholderClass::placeholder {
  color: red;
}

.cel_title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  isolation: isolate;

  width: 100%;
  position: relative;
  height: 160px;
}
.cel_title_icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 96px;
  height: 96px;

  /* Main/Purple-Tertiary */

  background: #f7f2fe;
  border-radius: 100px;
}
.cel_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  gap: 24px;

  width: 100%;
  position: relative;
}
.cel_info_text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}

.cel_info_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 6px;

  width: 100%;
  height: 48px;

  /* Main/Purple */

  background: #9747ff;
  border-radius: 100px;
}
.xp_info_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 6px;

  height: 48px;

  /* Main/Purple */

  background: #9747ff;
  border-radius: 100px;
}
.gm_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 8px;
}

.gm_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  margin: 24px 0;
}

.gm_title_text {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 135%;
  /* or 32px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Black */

  color: #190d1a;
}

.gm_title_text1 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* identical to box height, or 24px */

  text-align: center;
  letter-spacing: -0.01em;

  /* Main/Dark Gray */

  color: #434243;
}
.xpbox {
  box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
padding: 16px;
gap: 8px;

width: 100%;
height: 52px;

/* Main/White */

background: #FFFFFF;
/* Main/Purple */

border: 1px solid #F3F3F3;
border-radius: 16px;

}

.selectedXpBox {
  border: 1px solid #9747FF;
}

.xpboxTitle {
  font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 100%;
/* identical to box height, or 20px */

text-align: center;
letter-spacing: -0.01em;

/* Main/Purple */

color: #190D1A;
}

.selectedxpboxTitle {

color: #9747FF;
}

.xpboxTitleXp {
  font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 100%;
/* identical to box height, or 20px */

text-align: right;
letter-spacing: -0.01em;

/* Main/Black */

color: #190D1A;
}
.selectedxpboxTitleXp {
  color: #9747FF;
}


.closeIcon {
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
}
