.mainWrapper {

  width: 100%;
  height: 100%;
  padding: 24px;
  overflow-y: auto;
}

  .circularprogressBar {
    margin: 6px !important;
  }
  .ContentWrapper {
    margin: 6px;
    overflow-x: hidden;
  }
  .ZestHeading {
    padding-top: 8px;
    padding-bottom: 6px;
  }
  .ContentText {
    color: #434243;
    font-weight: 400;
    padding-top: 6px;
    line-height: 1.5rem;
    font-size: 16px;
    padding-bottom: 3px;
  }
  .QauetionText {
    color: #190d1a;
    font-weight: 400;
    padding: 5px 0px;
    margin-top: 8px;
  }
  .RadioOptionText {
    color: #434243;
    font-weight: 400;
    word-break: break-all;
    padding: 0.5px 0px;
  }


.circularprogressBar {
  margin: 6px !important;
}
.ContentWrapper {
  margin: 6px;
  overflow-x: hidden;
}
.ZestHeading {
  padding-top: 8px;
  padding-bottom: 6px;
}
.ContentText {
  color: #434243;
  font-weight: 400;
  padding-top: 6px;
  line-height: 1.5rem;
  font-size: 16px;
  padding-bottom: 3px;
}
.QauetionText {
  color: #190d1a;
  font-weight: 400;
  padding: 5px 0px;
  margin-top: 8px;
}
.RadioOptionText {
  color: #434243;
  font-weight: 400;
  word-break: break-all;
  padding: 4px 0px;
}

@media screen and (max-width: 1200px) {
  .mainWrapper {
    /* overflow-y: hidden; */
    height: auto;
  }
}
@media screen and (max-width: 600px) {
  .mainWrapper {
    padding: 10px 0px;
  }
}
