.dashbordTitle {
  margin-top: 20px;
}

.SearchBar {
  /* background: yellow; */
  margin-top: 32px;
}

.testListWrapper {
  margin-top: 16px;
  /* display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 12px; */
  margin-bottom: 20px;
}

.progressBarWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  /* background: blue; */
}

.progress {
  height: 12px;
  border-radius: 100px;
  background: #f7f2fe;
}

.progress span {
  border-radius: 100px;
}

.cardHeader {
  margin-top: 12px;
}

.card {
  height: 296px;
  background: #ffffff;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.imageWrapper img {
  width: 100%;
  max-height: 96px;
  border-radius: 12px;
  object-fit: cover;
}
.imageWrapper {
  position: relative;
}
.imageWrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 97%;
  width: 100%; /* set to 100% for full overlay width */
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(0, 0, 0, 0.3) 100%
  );
  border-radius: 12px;
}

.iconsWrapper {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  max-height: 24px;

  /* padding: 12px 14px; */
  /* background-color: #9747FF; */
}
.iconsLeftWrapperDisable {
  /* width: 152px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 12px;
  left: 12px;
}
.commingSoonicon {
  padding: 4px 8px;
  background: #f7f2fe;
  border-radius: 100px;
  /* width: 45px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.recommendedicon {
  padding: 4px 8px;
  background: #FFF;
  border-radius: 100px;
  /* width: 45px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
