.calanderWrapper {
    padding: 24px;
  }
  .dateTimeRangePickerWrapper{
      width: 100%;
      display: flex;
      gap: 8px;
  }
  .dateTimeRangePickerSubWrapper {
      width: 100%;
    border: 1px solid #f3f3f3;
    border-radius: 8px;
    height: 48px;
    align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
  }
  .DateAndTimewrapper{
    display: flex;
    gap: 15px;
  }