.profileImageWrapper{
    display: flex;
    gap: 12px;
    align-items: center;
    padding: 12px 0px;
}
.profileImage{
    width: 72px;
    height: 72px;
    margin-right: 12px;
}
.uploadedImage{
    width: 72px;
    height: 72px;
    border-radius: 50%;
    z-index: 99;
    
}
.uploadBottonInput{
    max-width: 135px;
}
.uploadBottonInput[type=file]::file-selector-button {
       margin-right: 20px;
       border: none;
 
       background: #9747FF;
       padding: 10px 20px;
       border-radius: 10px;
       color: #fff;
       cursor: pointer;
       transition: background .2s ease-in-out;
     }
     .inputName{
        margin: 8px 0px 12px 0px;
        font-weight: 500;
        color: black;
     }
     .email_error{
           margin: 10px;
            font-size: 14px;
            color: #ee3434 ;
     }
     

    