/* disable card */
.cardTitle{
    margin: 10px 0;
    font-weight: 600;
    font-size: 28px;
}
.card {
    background: #ffffff;
    border-radius: 16px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    min-height: 260px;
  }
  
  .testListWrapper {
    margin: auto;
    margin-top: 4px;
  }
  
  .iconsWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    max-height: 24px;
  
    /* padding: 12px 14px; */
    /* background-color: #9747FF; */
  }
  
  
  .coinIconWrapper img {
    width: 12px;
    height: 12px;
  }
  
  
  .iconsLeftWrapperDisable {
    /* width: 152px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    position: absolute;
    top: 12px;
    left: 12px;
  }
  
  .iconsLeftWrapper {
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 12px;
    left: 12px;
  }
  
  .iconsRightWrapperDisable {
    /* width: 48px; */
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    position: absolute;
    top: 12px;
    right: 12px;
  }
  
  .commingSoonicon {
    padding: 4px 8px;
    background: #f7f2fe;
    border-radius: 100px;
    /* width: 45px; */
  
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .coinIconWrapperDisable {
    width: 47px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    background: #f9f9f9;
    border-radius: 100px;
  }
  
  .coinIconWrapper {
    width: 47px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    background: #ffffff;
    border-radius: 100px;
  }
  
  .timeIconDisable {
    background: #f9f9f9;
    border-radius: 100px;
    padding: 4px 8px;
    width: 53px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .timeIcon {
    background: #f7f2fe;
    border-radius: 100px;
    padding: 4px 8px;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
  }
  
  .testTitle {
    margin-top: 10px;
  }
  .testTitle span {
    font-weight: 500;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.01em;
    color: #190D1A;
  }
  
  .testTitleDisable {
    margin-top: 14px;
  }
  
  .testDiscription {
    margin-top: 8px;
  }
  
  .progressBarWrapper {
    display: flex;
    align-items: center;
    width: 100%;
    /* background: blue; */
  }
  
  .progressDisabeled {
    height: 12px;
    border-radius: 1000px;
    background: #f9f9f9;
  }
  
  .progressDisabeled span {
    background: #dad6da;
  }
  
  .progress {
    height: 12px;
    border-radius: 1000px;
    background: #f7f2fe;
  }
  
  .progress span {
  
    background: #9747FF;
  }
  
  
  .avatarGroupWrapper {
    /* width: 72px;
      height: 24px; */
  }
  
  .iconsRightWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  
    position: absolute;
    top: 12px;
    right: 12px;
  }
  
  .bookMarkIcon {
    margin-left: 13px;
    display: flex;
  }
  
  .shareIcon {
    display: flex;
    margin-left: 4px;
  }
  
  .shareIconDisable {
    margin-left: 8px;
    display: flex;
  }
  
  
  /* .testPrgressWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  } */
  
  .retakeButtonWrapper {
    padding: 8px 12px;
    background: #f7f2fe;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  
  .testScores {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 5px;
  }
  
  .testResultScore {
    margin-left: 5px;
  }
  
  .imageWrapper {
    position: relative;
  }
  
  .imageWrapper img {
    width: 100%;
    max-height: 120px;
    border-radius: 12px;
  }
  
  .ml4{
    margin-left: 4px;
  }
  
  .Imageoverlay{
    position: relative;
    
  }
  .Imageoverlay img{
    object-fit: cover;
    height: 120px;
  }
  .Imageoverlay::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 98%;
    width: 100%; /* set to 100% for full overlay width */
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3) 0%,rgba(0, 0, 0, 0.3) 
      100%); 
    border-radius: 12px;
  } 
  
  .cardTimerBar {
    display: flex;
    border: 1px solid #bbb;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    color: #bbb
  }

  .maincontainerWrapper {
    width: 100%;
    /* height: 80px; */
    background: #ffffff;
    border: 1px solid #f3f3f3;
    border-radius: 16px;
    padding: 5px;
    display: flex;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .mainWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  
  .headerTitleWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .confirmationModalcardTitle {
    font-weight: 600;
font-size: 24px;

text-align: center;
letter-spacing: -0.01em;

/* Main/Black */

color: #190D1A;
  }

  .confirmationButtonDiv {
    display: flex;
    justify-content: space-between;
  }

  .confirmationButtonDiv button{
    width: 49%;
  }


.mainWrapper{
    display: flex;
    /* align-items: center; */
    justify-content: center;
    position: relative;
}
.mainWrapperSuccess{
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.testEndPageWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 546px;
    margin: 100px
}
.coinIconWrapper{
    width: max-content;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f7f2fe;
    border-radius: 100px;

    margin-top: 8px;
    gap: 5px;
}

.earnedMsg{
    margin-top: 20px;
}

/* button */

.bottomContainerWrapper{
    /* display: flex;
    align-items: center;
    justify-content: center; */
    width: 85%;
    position: absolute;
    bottom: 0;
    width: 160px;
    height: 48px;
    /* left: calc(50% - 123px/2); */
    bottom: 40px;
}

.previousButton {
    padding: 18px 20px;
    width: 100%;
    border: 1px solid #9747ff;
    color: #9747ff;
    background: #f9f9f9;
    border-radius: 16px;
  }
  
  
  .previousButton:hover {
    /* border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff; */

    border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff;
  }
  
  .nextButton {
    padding: 18px 20px;
    width: 100%;
    /* border: 1px solid #9747FF; */
    border-color: #9747ff;
    background: #9747ff;
    border-radius: 16px;
    color: #ffffff;
  }
  
  .nextButton:hover {
    /* background: pink; */
    /* border: 1px solid #9747ff;
    color: #9747ff;
    background: #ffffff; */

    border: 1px solid #9747ff;
    color: #ffffff;
    background: #9747ff;
  }
  
  .closeIcon {
    cursor: pointer;
  }